/* eslint-disable jsx-a11y/anchor-is-valid */
import { useLocation } from "react-router-dom";
import LinkC from "./MenuLink";

const MenuC = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  const handleLogout = () => {
    sessionStorage.clear();
    window.location.replace("/login");
  };

  const closeMenu = () => {
    const body = document.querySelector("body");
    if (body) {
      body.classList.remove("sidebar-open");
    }
  };

  return (
    <>
      <aside
        className="main-sidebar elevation-4"
        style={{ backgroundColor: "#d8e2ef" }}
      >
        <div className="sidebar">
          {/* Sidebar user panel (optional) */}
          <div className="user-panel mt-3 pb-3 mb-3 d-flex">
            <div className="image">
              <img
                src="/backend/dist/img/user2-160x160.jpg"
                className="img-circle elevation-2"
                alt="User Image"
              />
            </div>
            <div className="info">
              <a href="#" className="d-block">
                {sessionStorage.getItem("username")}
              </a>
            </div>
          </div>
          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              <LinkC
                href="/dashboard"
                icon="fas fa-tachometer-alt"
                onClick={closeMenu}
              >
                Dashboard
              </LinkC>
              <LinkC href="/anggota" icon="fas fa-th" onClick={closeMenu}>
                Master Data{" "}
              </LinkC>
              <li className="nav-header">Menu</li>
              <LinkC href="/artikel" icon="fa fa-blog" onClick={closeMenu}>
                Artikel
              </LinkC>
              <LinkC href="/file_arc" icon="fa fa-archive" onClick={closeMenu}>
                Archive
              </LinkC>
              <LinkC href="/media" icon="fa fa-image" onClick={closeMenu}>
                Kegiatan
              </LinkC>
              <LinkC
                href="/faq"
                icon="far fa-question-circle"
                onClick={closeMenu}
              >
                FAQ
              </LinkC>
              <LinkC href="/kritik" icon="far fa-envelope" onClick={closeMenu}>
                Kritik dan Saran
              </LinkC>
              <LinkC href="/tentang" icon="fa fa-home" onClick={closeMenu}>
                Tentang Kami
              </LinkC>
              <li className="nav-header">Pengaturan</li>
              <LinkC href="/user" icon="fa fa-users" onClick={closeMenu}>
                Manjemen User
              </LinkC>
              <LinkC
                href="#"
                onClick={() => {
                  handleLogout();
                  closeMenu();
                }}
                icon="fa fa-sign-out-alt"
              >
                Logout
              </LinkC>
            </ul>
          </nav>
          {/* /.sidebar-menu */}
        </div>
        {/* /.sidebar */}
      </aside>
    </>
  );
};

export default MenuC;
