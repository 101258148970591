/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LayoutFrontend from "../component/frontend/MainLayout";
import { About, fetchKontakPublic } from "../backend/tentang/Controller";
import {
  fetchHomePengurus,
  Pengurus,
} from "../backend/master/pengurus/Controller";
import { Faq, fetchFaq } from "../backend/faq/Controller";
import { Artikel, fetchBlog } from "../backend/blog/artikel/Controller";
import { Anggota } from "../backend/master/anggota/Controller";
import { Helmet } from "react-helmet";

const Home = () => {
  const [kontak, setKontak] = useState<About>();
  const [anggota, setAnggota] = useState<Anggota[]>([]);
  const [artikel, setArtikel] = useState<Artikel[]>([]);
  const [pengurus, setPengurus] = useState<Pengurus[]>([]);
  const [faq, setFaq] = useState<Faq[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    document.title = "";

    dataApiFaq();
    dataApiKontak();
    dataApiBlog();
    dataApiPengurus();
  }, []);

  const dataApiFaq = async () => {
    try {
      const faqResult = await fetchFaq();
      setFaq(faqResult);
    } catch (error) {}
  };
  const dataApiKontak = async () => {
    try {
      const kontakResult = await fetchKontakPublic();
      setKontak(kontakResult);
    } catch (error) {}
  };
  const dataApiBlog = async () => {
    setIsLoading(true);
    try {
      const artikelResult = await fetchBlog();
      const limitedArtikel = artikelResult.slice(0, 3);
      setArtikel(limitedArtikel);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  const dataApiPengurus = async () => {
    setIsLoading(true);
    try {
      const pengurusResult = await fetchHomePengurus();
      setPengurus(pengurusResult);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  const truncateText = (text: any, maxLength: any) => {
    if (!text) return "";
    return text.length > maxLength
      ? text.substring(0, maxLength) + "..."
      : text;
  };

  return (
    <>
      {" "}
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content={
            "Web organisasi HMJIT yang berada dibawah naungan ITB Widya Gama Lumajang"
          }
        />
        <meta property="og:title" content={"Selamat Datang di Web HMJIT"} />
        <meta
          property="og:description"
          content={
            "Web organisasi HMJIT yang berada dibawah naungan ITB Widya Gama Lumajang"
          }
        />
        <meta property="og:image" content={`/hmjit.png`} />
        <meta property="og:url" content={window.location.href} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={"Selamat Datang di Web HMJIT"} />
        <meta
          name="twitter:description"
          content="Web organisasi HMJIT yang berada dibawah naungan ITB Widya Gama Lumajang"
        />
        <meta name="twitter:image" content={`/hmjit.png`} />
      </Helmet>
      <LayoutFrontend>
        <div className="container-fluid feature bg-light py-1">
          <div className="container py-5">
            <div className="row g-4">
              <div
                className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp"
                data-wow-delay="0.2s"
              >
                <div className="feature-item p-4 pt-0">
                  <div className="feature-icon p-4 mb-4">
                    <i className="far fa-handshake fa-3x" />
                  </div>
                  <h4 className="mb-4">Kegiatan</h4>
                  <p className="mb-4">
                    Kegiatan yang sering dilakukan oleh Himpunan Mahasiswa
                    Jurusan Informatika
                  </p>
                  <Link
                    className="btn btn-primary rounded-pill py-2 px-4"
                    to={"/kegiatan"}
                  >
                    Selengkapnya
                  </Link>
                </div>
              </div>
              <div
                className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp"
                data-wow-delay="0.4s"
              >
                <div className="feature-item p-4 pt-0">
                  <div className="feature-icon p-4 mb-4">
                    <i className="fa fa-dollar-sign fa-3x" />
                  </div>
                  <h4 className="mb-4">Anggota</h4>
                  <p className="mb-4">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea
                    hic laborum odit pariatur...
                  </p>
                  <Link
                    className="btn btn-primary rounded-pill py-2 px-4"
                    to={"/member"}
                  >
                    Selengkapnya
                  </Link>
                </div>
              </div>
              <div
                className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp"
                data-wow-delay="0.6s"
              >
                <div className="feature-item p-4 pt-0">
                  <div className="feature-icon p-4 mb-4">
                    <i className="fa fa-bullseye fa-3x" />
                  </div>
                  <h4 className="mb-4">Archive</h4>
                  <p className="mb-4">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea
                    hic laborum odit pariatur...
                  </p>
                  <Link
                    className="btn btn-primary rounded-pill py-2 px-4"
                    to={"/archive"}
                  >
                    Selengkapnya
                  </Link>
                </div>
              </div>
              {/* <div
                className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp"
                data-wow-delay="0.8s"
              >
                <div className="feature-item p-4 pt-0">
                  <div className="feature-icon p-4 mb-4">
                    <i className="fa fa-headphones fa-3x" />
                  </div>
                  <h4 className="mb-4">24/7 Fast Support</h4>
                  <p className="mb-4">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea
                    hic laborum odit pariatur...
                  </p>
                  <a
                    className="btn btn-primary rounded-pill py-2 px-4"
                    href="#"
                  >
                    Selengkapnya
                  </a>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="container-fluid bg-light about pb-1">
          <div className="container pb-1">
            <div className="row g-5">
              <div className="col-xl-6 wow fadeInLeft" data-wow-delay="0.2s">
                <div className="about-item-content bg-white rounded p-5 h-100">
                  <h4 className="text-primary">Tentang Organisasi</h4>
                  <h1 className="">Himpunan Mahasiswa Jurusan Informatika</h1>
                  <div
                    dangerouslySetInnerHTML={{ __html: kontak?.des ?? "" }}
                  ></div>
                </div>
              </div>
              <div className="col-xl-6 wow fadeInRight" data-wow-delay="0.2s">
                <div className="bg-white rounded p-5 h-100">
                  <div className="row g-4 justify-content-center">
                    <div className="col-12">
                      <div className="rounded bg-light">
                        <img
                          src="/frontend/assets/img/about-1.png"
                          className="img-fluid rounded w-100"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="counter-item bg-light rounded p-3 h-100">
                        <div className="counter-counting">
                          <span
                            className="text-primary fs-2 fw-bold"
                            data-toggle="counter-up"
                          >
                            {anggota ? anggota.length : 0}
                          </span>
                          <span className="h1 fw-bold text-primary">+</span>
                        </div>
                        <h4 className="mb-0 text-dark">Anggota</h4>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="counter-item bg-light rounded p-3 h-100">
                        <div className="counter-counting">
                          <span
                            className="text-primary fs-2 fw-bold"
                            data-toggle="counter-up"
                          >
                            99
                          </span>
                          <span className="h1 fw-bold text-primary">+</span>
                        </div>
                        <h4 className="mb-0 text-dark">Pengurus</h4>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="counter-item bg-light rounded p-3 h-100">
                        <div className="counter-counting">
                          <span
                            className="text-primary fs-2 fw-bold"
                            data-toggle="counter-up"
                          >
                            3
                          </span>
                          <span className="h1 fw-bold text-primary">+</span>
                        </div>
                        <h4 className="mb-0 text-dark">Prestasi</h4>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="counter-item bg-light rounded p-3 h-100">
                        <div className="counter-counting">
                          <span
                            className="text-primary fs-2 fw-bold"
                            data-toggle="counter-up"
                          >
                            967
                          </span>
                          <span className="h1 fw-bold text-primary">+</span>
                        </div>
                        <h4 className="mb-0 text-dark">Kegiatan</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid faq-section bg-light py-5">
          <div className="container py-1">
            <div className="row g-5 align-items-center">
              <div className="col-xl-6 wow fadeInLeft" data-wow-delay="0.2s">
                <div className="h-100">
                  <div className="mb-5">
                    <h4 className="text-primary">Beberapa FAQ Penting</h4>
                    <h1 className="display-4 mb-0">
                      Pertanyaan Umum yang Sering Diajukan
                    </h1>
                  </div>
                  <div className="accordion" id="accordionExample">
                    {faq ? (
                      faq.map((faq, index) => (
                        <>
                          <div className="accordion-item" key={faq.id_faq}>
                            <h2 className="accordion-header" id="headingOne">
                              <button
                                className={`accordion-button ${
                                  index === 0 ? "border-0" : "collapsed"
                                }`}
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={`#collapseOne${faq.id_faq}`}
                                aria-expanded={index === 0 ? "true" : "false"}
                                aria-controls={`collapseOne${faq.id_faq}`}
                              >
                                {faq.soal1}
                              </button>
                            </h2>
                            <div
                              id={`collapseOne${faq.id_faq}`}
                              className={`accordion-collapse collapse ${
                                index === 0 ? "show active" : ""
                              }`}
                              aria-labelledby="headingOne"
                              data-bs-parent="#accordionExample"
                            >
                              <div className="accordion-body rounded">
                                {faq.jawab1}
                              </div>
                            </div>
                          </div>
                        </>
                      ))
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-xl-6 wow fadeInRight" data-wow-delay="0.4s">
                <img
                  src="/frontend/assets/img/carousel-2.png"
                  className="img-fluid w-100"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid blog py-1">
          <div className="container py-5">
            <div
              className="text-center mx-auto pb-5 wow fadeInUp"
              data-wow-delay="0.2s"
              style={{ maxWidth: 800 }}
            >
              <h4 className="text-primary">Artikel</h4>
              <h1 className="display-4 mb-4">Terbaru dan Update</h1>
            </div>
            <div className="row g-4 justify-content-center">
              {isLoading ? (
                <div className="col-lg-6 col-xl-4 wow fadeInUp">
                  <div className="blog-item">
                    <div className="blog-img">
                      <img
                        src="https://cloud-atg.moph.go.th/quality/sites/default/files/default_images/default.png"
                        alt=""
                      />
                    </div>
                    <div className="blog-content p-4">
                      <div className="blog-comment d-flex justify-content-between mb-3">
                        <div className="small">
                          <span className="fa fa-user text-primary" /> Loading
                        </div>
                        <div className="small">
                          <span className="fa fa-calendar text-primary" />{" "}
                          Loading
                        </div>
                        <div className="small">
                          <span className="fa fa-comments text-primary" />{" "}
                          Loading
                        </div>
                      </div>
                      <h4 className="mb-3">Loading</h4>
                      <p> Loading</p>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  {artikel.map((artikel) => (
                    <div
                      className="col-lg-6 col-xl-4 wow fadeInUp"
                      data-wow-delay="0.2s"
                      key={artikel.id_artikel}
                    >
                      <div className="blog-item">
                        <div className="blog-img">
                          <img
                            src={`${process.env.REACT_APP_API_IMAGE}/storage/${artikel.gambar}`}
                            className="img-fluid rounded-top w-100"
                            alt=""
                          />
                          <div className="blog-categiry py-2 px-4">
                            <span>{artikel.nama}</span>
                          </div>
                        </div>
                        <div className="blog-content p-4">
                          <div className="blog-comment d-flex justify-content-between mb-3">
                            <div className="small">
                              <span className="fa fa-user text-primary" />{" "}
                              {artikel.penulis}
                            </div>
                            <div className="small">
                              <span className="fa fa-calendar text-primary" />{" "}
                              {artikel.tanggal}
                            </div>
                            <div className="small">
                              <span className="fa fa-eye text-primary" /> 6
                            </div>
                          </div>
                          <a
                            href={`/blog/${artikel.slug}`}
                            className="h4 d-inline-block mb-3"
                          >
                            {truncateText(artikel.judul, 50)}
                          </a>
                          <p className="mb-3">{artikel.excerpt}....</p>
                          <a href={`/blog/${artikel.slug}`} className="btn p-0">
                            Baca <i className="fa fa-arrow-right" />
                          </a>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
        </div>

        <div className="container-fluid team pb-5">
          <div className="container pb-1">
            <div
              className="text-center mx-auto pb-1 wow fadeInUp"
              data-wow-delay="0.2s"
              style={{ maxWidth: 800 }}
            >
              <h4 className="text-primary">Koordinator</h4>
              <h1 className="display-4 mb-4">Anggota Koordinasi HMJIT</h1>
            </div>
            <div className="row g-4">
              {isLoading ? (
                <>
                  <div className="col-6 col-md-4 col-lg-3 wow fadeInUp">
                    <div className="team-item">
                      <div className="team-img">
                        <img
                          src="https://pngpix.com/images/featured/default-png-ixk4xl86qvxyv5lx.jpg"
                          className="img-fluid rounded-top w-100"
                        />
                      </div>
                      <div className="team-text">
                        <h4>Loading</h4>
                        <p>Loading</p>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {pengurus.map((pengurus) => (
                    <div
                      className="col-6 col-md-4 col-lg-3 wow fadeInUp"
                      data-wow-delay="0.2s"
                      style={{
                        visibility: "visible",
                        animationDelay: "0.2s",
                        animationName: "fadeInUp",
                      }}
                      key={pengurus.id_team}
                    >
                      <div className="team-item">
                        <div className="team-img">
                          <img
                            src={
                              pengurus.gambar === null
                                ? "https://pngpix.com/images/featured/default-png-ixk4xl86qvxyv5lx.jpg"
                                : ""
                            }
                            className="img-fluid rounded-top w-100"
                            alt=""
                          />
                          <div className="team-icon">
                            <Link
                              className="btn btn-primary btn-sm-square rounded-pill mb-2"
                              to={pengurus.facebook}
                              target="_blank"
                            >
                              <i className="fab fa-facebook-f" />
                            </Link>
                            <Link
                              className="btn btn-primary btn-sm-square rounded-pill mb-2"
                              to={pengurus.twitter}
                              target="_blank"
                            >
                              <i className="fab fa-twitter" />
                            </Link>
                            <Link
                              className="btn btn-primary btn-sm-square rounded-pill mb-2"
                              to={pengurus.tiktok}
                              target="_blank"
                            >
                              <i className="fab fa-tiktok" />
                            </Link>
                            <Link
                              className="btn btn-primary btn-sm-square rounded-pill mb-0"
                              to={pengurus.instagram}
                              target="_blank"
                            >
                              <i className="fab fa-instagram" />
                            </Link>
                          </div>
                        </div>
                        <div className="team-title p-4">
                          <h4 className="mb-0">{pengurus.nama}</h4>
                          <p className="mb-0">{pengurus.sub}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
      </LayoutFrontend>
    </>
  );
};

export default Home;
