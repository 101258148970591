import { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { fetchFormData, validateFormData, submitFormData } from "./Controller";
import { useStateContext } from "../../middleware";
import FormC from "../../component/backend/Form";
import LayoutBackend from "../../component/backend/Layout";
import InputC from "../../component/backend/Input";

const AksiMedia = () => {
  const { id } = useParams();
  const isEdit = Boolean(id);
  const navigate = useNavigate();
  const { token } = useStateContext();
  const [formData, setFormData] = useState({
    judul: "",
    user_id: sessionStorage.getItem("user"),
    gambar: new File([], ""),
  });
  const [errors, setErrors] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  useEffect(() => {
    document.title = isEdit ? "Edit Kegiatan" : "Tambah Kegiatan";
    if (id) {
      fetchFormData(id)
        .then((data) => setFormData(data))
        .catch((error) => console.error("Error:", error));
    }
  }, [id, isEdit]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value, files } = e.target as HTMLInputElement;
    if (files && files.length > 0) {
      const file = files[0];
      if (file.size > 2 * 1024 * 1024) {
        // Memeriksa apakah ukuran file melebihi 2MB
        alert("Ukuran file tidak boleh melebihi 2MB!");
        return; // Menghentikan pemrosesan lebih lanjut
      }
      // Membuat URL sementara untuk prapratampilan gambar
      const imageUrl = URL.createObjectURL(file);
      setImagePreview(imageUrl);
      setFormData((prevData) => ({
        ...prevData,
        [name]: files[0],
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    const validationErrors = validateFormData(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setIsLoading(false);
      return;
    }
    console.log(formData);
    try {
      await submitFormData(formData, isEdit);
      navigate("/media", {
        state: {
          message: isEdit
            ? "Data berhasil diperbarui!"
            : "Data berhasil ditambahkan!",
        },
      });
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };
  if (!token) {
    return <Navigate to="/login" />;
  }
  return (
    <LayoutBackend>
      <FormC
        aksi={handleSubmit}
        formTitle={isEdit ? `Edit Kegiatan` : `Tambah Kegiatan`}
        buttonTitle={isEdit ? `Update` : `Simpan`}
        buttonType={`submit`}
        hrefTitle={`Kembali`}
        href={`/media`}
        loading={isLoading}
        encType={`multipart/form-data`}
      >
        <div className="col-12">
          <InputC
            label={`Judul Kegiatan`}
            input_type={`text`}
            input_id={`judul`}
            name={"judul"}
            placeholder={`Masukkan nama kegiatan`}
            value={formData.judul}
            onChange={handleChange}
          />
          <span className="text-danger">{errors.judul}</span>
        </div>
        <div className="col-12">
          <InputC
            label={`Upload Dokumentasi`}
            input_type={`file`}
            input_id={`gambar`}
            name={"gambar"}
            onChange={handleChange}
          />
          {isEdit ? (
            <img
              src={`${process.env.REACT_APP_API_URL}/apihmjit/public/storage/${formData.gambar}`}
              className="img-fluid"
              width={100}
              alt=""
            />
          ) : (
            imagePreview && (
              <>
                <img
                  src={imagePreview}
                  className="img-fluid"
                  width={50}
                  alt="Preview"
                />
              </>
            )
          )}
        </div>
      </FormC>
    </LayoutBackend>
  );
};

export default AksiMedia;
