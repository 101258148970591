import { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { fetchFormData, validateFormData, submitFormData } from "./Controller";
import LayoutBackend from "../../component/backend/Layout";
import FormC from "../../component/backend/Form";
import { useStateContext } from "../../middleware";
import InputC from "../../component/backend/Input";

const AksiFaq = () => {
  const { id } = useParams();
  const isEdit = Boolean(id);
  const navigate = useNavigate();
  const { token } = useStateContext();
  const [formData, setFormData] = useState({
    soal1: "",
    jawab1: "",
  });
  const [errors, setErrors] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    document.title = isEdit ? "Edit FAQ" : "Tambah FAQ";
    if (id) {
      fetchFormData(id)
        .then((data) => setFormData(data))
        .catch((error) => console.error("Error:", error));
    }
  }, [id, isEdit]);

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target as HTMLInputElement;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    const validationErrors = validateFormData(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setIsLoading(false);
      return;
    }

    try {
      await submitFormData(formData, isEdit);
      navigate("/faq", {
        state: {
          message: isEdit
            ? "Data berhasil diperbarui!"
            : "Data berhasil ditambahkan!",
        },
      });
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  if (!token) {
    return <Navigate to="/login" />;
  }

  return (
    <LayoutBackend>
      <FormC
        aksi={handleSubmit}
        formTitle={isEdit ? `Edit FAQ` : `Tambah FAQ`}
        buttonTitle={isEdit ? `Update` : `Simpan`}
        buttonType={`submit`}
        hrefTitle={`Kembali`}
        href={`/faq`}
        loading={isLoading}
        encType={`multipart/form-data`}
      >
        <div className="col-12">
          <InputC
            label="Soal"
            name="soal1"
            input_id="soal1"
            input_type="text"
            value={formData.soal1}
            onChange={handleChange}
            placeholder="Soal"
          />
          <span className="text-danger">{errors.soal1}</span>
        </div>
        {isEdit && (
          <>
            <label htmlFor="jawab1">Jawaban</label>
            <div className="col-12">
              <textarea
                className="form-control"
                name="jawab1"
                id="jawab1"
                value={formData.jawab1} // Correct way to set value
                onChange={handleChange}
                placeholder="Jawaban"
              />
              <span className="text-danger">{errors.jawab1}</span>
            </div>
          </>
        )}
        {!isEdit && (
          <>
            <label htmlFor="jawab1">Jawaban</label>
            <div className="col-12">
              <textarea
                className="form-control"
                name="jawab1"
                id="jawab1"
                onChange={handleChange}
                placeholder="Jawaban"
              />
              <span className="text-danger">{errors.jawab1}</span>
            </div>
          </>
        )}
      </FormC>
    </LayoutBackend>
  );
};

export default AksiFaq;
