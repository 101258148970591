/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from "react-router-dom";
import LinkC from "../Link";

const HeaderC = () => {
  const handleCari = async () => {
    const cari = document.getElementById("cari") as HTMLInputElement;
    const data = {
      keyword: cari.value,
    };
    try {
      window.location.href = `/cari?${new URLSearchParams(data).toString()}`;
    } catch (error) {
      console.error("Error:", error);
    }
  };
  return (
    <>
      <div className="container-fluid nav-bar px-0 px-lg-4 py-lg-0">
        <div className="container">
          <nav className="navbar navbar-expand-lg navbar-light">
            <Link to="/" className="navbar-brand p-0">
              <h1 className="text-primary mb-0">
                <img src="/hmjit.png" alt="Logo" /> HMJIT
              </h1>
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarCollapse"
            >
              <span className="fa fa-bars" />
            </button>
            <div className="collapse navbar-collapse" id="navbarCollapse">
              <div className="navbar-nav mx-0 mx-lg-auto">
                <LinkC
                  href="/"
                  active={window.location.pathname === "/" ? "active" : ""}
                >
                  Beranda
                </LinkC>
                <a
                  href="/blog"
                  className={`nav-item nav-link ${
                    window.location.pathname === "/blog" ? "active" : ""
                  }`}
                >
                  Artikel
                </a>

                <LinkC
                  href="/dokumentasi"
                  active={
                    window.location.pathname === "/dokumentasi" ||
                    window.location.pathname === "/archive" ||
                    window.location.pathname === "/kegiatan"
                      ? "active"
                      : ""
                  }
                >
                  Dokumentasi
                </LinkC>
                <LinkC
                  href="/kontak"
                  active={
                    window.location.pathname === "/kontak" ? "active" : ""
                  }
                >
                  Kontak
                </LinkC>

                <div className="nav-btn px-3">
                  <button
                    className="btn-search btn btn-primary btn-md-square rounded-circle flex-shrink-0"
                    data-bs-toggle="modal"
                    data-bs-target="#searchModal"
                  >
                    <i className="fas fa-search" />
                  </button>
                  <Link
                    to="/daftar"
                    className="btn btn-primary rounded-pill py-2 px-4 ms-3 flex-shrink-0"
                  >
                    {" "}
                    Register
                  </Link>
                </div>
              </div>
            </div>
            <div className="d-none d-xl-flex flex-shrink-0 ps-4">
              <a
                href="#"
                className="btn btn-light btn-lg-square rounded-circle position-relative wow tada"
                data-wow-delay=".9s"
              >
                <i className="fa fa-map-marker fa-2x" />
                <div
                  className="position-absolute"
                  style={{ top: 7, right: 12 }}
                ></div>
              </a>
              <div className="d-flex flex-column ms-3">
                <span>Lokasi</span>
                <a href="tel:+ 0123 456 7890">
                  <span className="text-dark">Jln. Veteran Lumajang</span>
                </a>
              </div>
            </div>
          </nav>
        </div>
      </div>
      <div
        className="modal fade"
        id="searchModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-fullscreen">
          <div className="modal-content rounded-0">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Search by keyword
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body d-flex align-items-center bg-primary">
              <div className="input-group w-75 mx-auto d-flex">
                <input
                  type="text"
                  id="cari"
                  className="form-control p-3"
                  placeholder="keywords"
                  aria-describedby="search-icon-1"
                />
                <span
                  id="search-icon-1"
                  className="btn bg-light border nput-group-text p-3"
                  onClick={handleCari}
                >
                  <i className="fa fa-search" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderC;
