import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./frontend/Home";
import KontakPage from "./frontend/Kontak";
import DokumentasiPage from "./frontend/Dokumentasi";
import KegiatanPage from "./frontend/Kegiatan";
import DashboardPage from "./backend/Dashboard";
import LoginPage from "./backend/Login";
import AnggotaPage from "./backend/master/anggota/Anggota";
import AksiAnggota from "./backend/master/anggota/Aksi";
import { StateProvider } from "./middleware";
import AksiPengurus from "./backend/master/pengurus/Aksi";
import PengurusPage from "./backend/master/pengurus/Pengurus";
import KategoriPage from "./backend/blog/kategori/Kategori";
import AksiKategori from "./backend/blog/kategori/Aksi";
import ArtikelPage from "./backend/blog/artikel/Artikel";
import AksiArtikel from "./backend/blog/artikel/Aksi";
import FilePage from "./backend/file/File";
import AksiArchive from "./backend/file/Aksi";
import MediaPage from "./backend/media/Media";
import AksiMedia from "./backend/media/Aksi";
import FaqPage from "./backend/faq/Faq";
import AksiFaq from "./backend/faq/Aksi";
import KritikPage from "./backend/kritik/Kritik";
import AksiKritik from "./backend/kritik/Aksi";
import TentangPage from "./backend/tentang/Tentang";
import UserPage from "./backend/user/User";
import AksiUser from "./backend/user/Aksi";
import VideoPage from "./backend/video/Video";
import AksiVideo from "./backend/video/Aksi";
import ArchivePage from "./backend/file/Archive";
import SuratMasukPage from "./backend/file/surat/Masuk";
import AksiMasuk from "./backend/file/surat/AksiMasuk";
import SuratKeluarPage from "./backend/file/surat/Keluar";
import PdfGenerator from "./Pdf";
import DetailBlog from "./frontend/DetailBlog";
import BlogPage from "./frontend/Artikel";
import CariPage from "./frontend/Cari";
import ArchivePublicPage from "./frontend/ArchiveP";
import RegisterPage from "./frontend/Register";
import DetailAnggota from "./backend/master/anggota/Detail";
import MemberPublicPage from "./frontend/Member";

function App() {
  return (
    <StateProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/pdf" element={<PdfGenerator />} />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/kategori/:slug" element={<BlogPage />} />
          <Route path="/daftar" element={<RegisterPage />} />
          <Route path="/cari" element={<CariPage />} />
          <Route path="/blog/:slug" element={<DetailBlog />} />
          <Route path="/kontak" element={<KontakPage />} />
          <Route path="/dokumentasi" element={<DokumentasiPage />} />
          <Route path="/kegiatan" element={<KegiatanPage />} />
          <Route path="/archive" element={<ArchivePublicPage />} />
          <Route path="/dashboard" element={<DashboardPage />} />
          <Route path="/member" element={<MemberPublicPage />} />
          <Route path="/login" element={<LoginPage />} />

          <Route path="/anggota" element={<AnggotaPage />} />
          <Route path="/tambah_anggota" element={<AksiAnggota />} />
          <Route path="/anggota/:id" element={<AksiAnggota />} />
          <Route path="/detail_anggota/:id" element={<DetailAnggota />} />

          <Route path="/tambah_pengurus" element={<AksiPengurus />} />
          <Route path="/pengurus/:id" element={<AksiPengurus />} />
          <Route path="/pengurus" element={<PengurusPage />} />

          <Route path="/kategori" element={<KategoriPage />} />
          <Route path="/kategori/:id" element={<AksiKategori />} />
          <Route path="/tambah_kategori" element={<AksiKategori />} />

          <Route path="/artikel" element={<ArtikelPage />} />
          <Route path="/artikel/:id" element={<AksiArtikel />} />
          <Route path="/tambah_artikel" element={<AksiArtikel />} />

          <Route path="/file_arc" element={<FilePage />} />

          <Route path="/file_archive" element={<ArchivePage />} />
          <Route path="/file_arc/:id" element={<AksiArchive />} />
          <Route path="/tambah_archive" element={<AksiArchive />} />

          <Route path="/media" element={<MediaPage />} />
          <Route path="/media/:id" element={<AksiMedia />} />
          <Route path="/tambah_media" element={<AksiMedia />} />

          <Route path="/faq" element={<FaqPage />} />
          <Route path="/faq/:id" element={<AksiFaq />} />
          <Route path="/tambah_faq" element={<AksiFaq />} />

          <Route path="/kritik" element={<KritikPage />} />
          <Route path="/kritik/:id" element={<AksiKritik />} />
          <Route path="/tentang" element={<TentangPage />} />

          <Route path="/video" element={<VideoPage />} />
          <Route path="/video/:id" element={<AksiVideo />} />
          <Route path="/tambah_video" element={<AksiVideo />} />

          <Route path="/surat_masuk" element={<SuratMasukPage />} />
          <Route path="/surat_masuk/:id" element={<AksiMasuk />} />
          <Route path="/tambah_surat_masuk" element={<AksiMasuk />} />

          <Route path="/surat_keluar" element={<SuratKeluarPage />} />
          <Route path="/surat_keluar/:id" element={<AksiMasuk />} />
          <Route path="/tambah_surat_keluar" element={<AksiMasuk />} />

          <Route path="/user" element={<UserPage />} />
          <Route path="/user/:id" element={<AksiUser />} />
          <Route path="/tambah_user" element={<AksiUser />} />
        </Routes>
      </Router>
    </StateProvider>
  );
}

export default App;
