// AnggotaController.ts
export interface About {
  id_kontak: number;
  alamat: string;
  nomor: string;
  email: string;
  jam: string;
  twit: string;
  fby: string;
  ig: string;
  tiktok: string;
  gambar: string;
  des: string;
}

// Fungsi untuk mengambil data dari API
export const fetchKontak = async (): Promise<About> => {
  try {
    const token = sessionStorage.getItem("token");
    if (!token) {
      throw new Error("Token is missing!");
    }

    const response = await fetch(`${process.env.REACT_APP_API_URL}/about`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      console.error("Server Response:", await response.text()); // Log the full response
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data: About = await response.json();
    return data;
  } catch (error: any) {
    console.error("Fetch error:", error.message);
    throw error;
  }
};
export const fetchKontakPublic = async (): Promise<About> => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/public_kontak/${process.env.REACT_APP_TOKEN}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );

    if (!response.ok) {
      console.error("Server Response:", await response.text()); // Log the full response
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data: About = await response.json();
    return data;
  } catch (error: any) {
    console.error("Fetch error:", error.message);
    throw error;
  }
};

// controller.ts

export const fetchFormData = async (id: string) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/kegiatan/${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    );
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching form data:", error);
    throw error;
  }
};

export const validateFormData = (formData: any) => {
  const errors: any = {};

  if (!formData.judul) errors.judul = "Judul harus diisi!";
  if (!formData.gambar) errors.gambar = "File harus diisi!";

  return errors;
};

export const submitFormData = async (formData: any) => {
  const url = `${process.env.REACT_APP_API_URL}/update_about`;
  try {
    // Check if formData is already FormData, else convert it
    const body = formData instanceof FormData ? formData : new FormData();
    if (!(formData instanceof FormData)) {
      for (const key in formData) {
        body.append(key, formData[key]);
      }
    }

    const response = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: body,
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
  } catch (error) {
    console.error("Error submitting form data:", error);
    throw error;
  }
};
