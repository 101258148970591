/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useEffect, useState } from "react";
import LayoutFrontend from "../component/frontend/MainLayout";
import {
  Artikel,
  fetchBlog,
  getPaginatedData,
  getTotalPages,
} from "../backend/blog/artikel/Controller";
const BlogPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [artikel, setArtikel] = useState<Artikel[]>([]);
  const [kategori, setKategori] = useState<Artikel[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const articlesPerPage = 6;
  const totalPages =
    window.location.pathname === "/blog"
      ? getTotalPages(artikel, articlesPerPage)
      : getTotalPages(kategori, articlesPerPage);

  const paginatedData =
    window.location.pathname === "/blog"
      ? getPaginatedData(artikel, currentPage, articlesPerPage)
      : getPaginatedData(kategori, currentPage, articlesPerPage);
  const fetchData = async () => {
    setIsLoading(true);
    try {
      const [artikelResult] = await Promise.all([fetchBlog()]);
      setArtikel(artikelResult);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const fetchKategori = async () => {
    setIsLoading(true);
    try {
      const artikelResult = await fetchBlog();

      // Filter the kategori based on articleId
      const filteredKategori = artikelResult.filter(
        (item) => item.nama === window.location.pathname.split("/")[2]
      );

      setKategori(filteredKategori);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    document.title = "Halaman Artikel";

    window.location.pathname === "/blog" ? fetchData() : fetchKategori();
  }, []);
  const truncateText = (text: any, maxLength: any) => {
    if (!text) return "";
    return text.length > maxLength
      ? text.substring(0, maxLength) + "..."
      : text;
  };

  const handlePageChange = (newPage: number) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };
  return (
    <LayoutFrontend>
      {window.location.pathname === "/blog" ? (
        <>
          <div className="container-fluid bg-breadcrumb">
            <div
              className="container text-center py-5"
              style={{ maxWidth: 900 }}
            >
              <h4
                className="text-white display-4 mb-4 wow fadeInDown"
                data-wow-delay="0.1s"
              >
                Artikel
              </h4>
              <ol
                className="breadcrumb d-flex justify-content-center mb-0 wow fadeInDown"
                data-wow-delay="0.3s"
              >
                <li className="breadcrumb-item">
                  <a href="index.html">Beranda</a>
                </li>
                <li className="breadcrumb-item active text-primary">Artikel</li>
              </ol>
            </div>
          </div>
          <div className="container-fluid blog py-5">
            <div className="container py-5">
              <div className="row g-4 justify-content-center">
                {isLoading ? (
                  <p>Loading...</p>
                ) : (
                  <>
                    {paginatedData.map((artikel) => (
                      <div
                        className="col-lg-6 col-xl-4 wow fadeInUp"
                        data-wow-delay="0.2s"
                        key={artikel.id_artikel}
                      >
                        <div className="blog-item">
                          <div className="blog-img">
                            <img
                              src={`${process.env.REACT_APP_API_IMAGE}/storage/${artikel.gambar}`}
                              className="img-fluid rounded-top w-100"
                              alt=""
                            />
                            <div className="blog-categiry py-2 px-4">
                              <span>{artikel.nama}</span>
                            </div>
                          </div>
                          <div className="blog-content p-4">
                            <div className="blog-comment d-flex justify-content-between mb-3">
                              <div className="small">
                                <span className="fa fa-user text-primary" />{" "}
                                {artikel.penulis}
                              </div>
                              <div className="small">
                                <span className="fa fa-calendar text-primary" />{" "}
                                {artikel.tanggal}
                              </div>
                              <div className="small">
                                <span className="fa fa-eye text-primary" /> 6
                              </div>
                            </div>
                            <a
                              href={`/blog/${artikel.slug}`}
                              className="h4 d-inline-block mb-3"
                            >
                              {truncateText(artikel.judul, 50)}
                            </a>
                            <p className="mb-3">{artikel.excerpt}....</p>
                            <a
                              href={`/blog/${artikel.slug}`}
                              className="btn p-0"
                            >
                              Baca <i className="fa fa-arrow-right" />
                            </a>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>
              <div className="d-flex justify-content-center mt-4">
                <button
                  className="btn btn-primary me-2"
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  <i className="fa fa-arrow-left"></i>
                </button>
                {[...Array(totalPages)].map((_, pageIndex) => (
                  <button
                    key={pageIndex}
                    className={`btn btn-primary me-2 ${
                      currentPage === pageIndex + 1 ? "active" : ""
                    }`}
                    onClick={() => handlePageChange(pageIndex + 1)}
                  >
                    {pageIndex + 1}
                  </button>
                ))}
                <button
                  className="btn btn-primary"
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  <i className="fa fa-arrow-right"></i>
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="container-fluid bg-breadcrumb">
            <div
              className="container text-center py-5"
              style={{ maxWidth: 900 }}
            >
              <h4
                className="text-white display-4 mb-4 wow fadeInDown"
                data-wow-delay="0.1s"
              >
                Kategori
              </h4>
              <ol
                className="breadcrumb d-flex justify-content-center mb-0 wow fadeInDown"
                data-wow-delay="0.3s"
              >
                <li className="breadcrumb-item">
                  <a href="index.html">Beranda</a>
                </li>
                <li className="breadcrumb-item active text-primary">Artikel</li>
              </ol>
            </div>
          </div>
          <div className="container-fluid blog py-5">
            <div className="container py-5">
              <div className="row g-4 justify-content-center">
                {isLoading ? (
                  <p>Loading...</p>
                ) : (
                  <>
                    {paginatedData.map((artikel) => (
                      <div
                        className="col-lg-6 col-xl-4 wow fadeInUp"
                        data-wow-delay="0.2s"
                        key={artikel.id_artikel}
                      >
                        <div className="blog-item">
                          <div className="blog-img">
                            <img
                              src={`${process.env.REACT_APP_API_IMAGE}/storage/${artikel.gambar}`}
                              className="img-fluid rounded-top w-100"
                              alt=""
                            />
                            <div className="blog-categiry py-2 px-4">
                              <span>{artikel.nama}</span>
                            </div>
                          </div>
                          <div className="blog-content p-4">
                            <div className="blog-comment d-flex justify-content-between mb-3">
                              <div className="small">
                                <span className="fa fa-user text-primary" />{" "}
                                {artikel.penulis}
                              </div>
                              <div className="small">
                                <span className="fa fa-calendar text-primary" />{" "}
                                {artikel.tanggal}
                              </div>
                              <div className="small">
                                <span className="fa fa-eye text-primary" /> 6
                              </div>
                            </div>
                            <a
                              href={`/blog/${artikel.slug}`}
                              className="h4 d-inline-block mb-3"
                            >
                              {truncateText(artikel.judul, 50)}
                            </a>
                            <p className="mb-3">{artikel.excerpt}....</p>
                            <a
                              href={`/blog/${artikel.slug}`}
                              className="btn p-0"
                            >
                              Baca <i className="fa fa-arrow-right" />
                            </a>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>
              <div className="d-flex justify-content-center mt-4">
                <button
                  className="btn btn-primary me-2"
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  <i className="fa fa-arrow-left"></i>
                </button>
                {[...Array(totalPages)].map((_, pageIndex) => (
                  <button
                    key={pageIndex}
                    className={`btn btn-primary me-2 ${
                      currentPage === pageIndex + 1 ? "active" : ""
                    }`}
                    onClick={() => handlePageChange(pageIndex + 1)}
                  >
                    {pageIndex + 1}
                  </button>
                ))}
                <button
                  className="btn btn-primary"
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  <i className="fa fa-arrow-right"></i>
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </LayoutFrontend>
  );
};

export default BlogPage;
