import { Link } from "react-router-dom";

const LinkC = (props: any) => {
  const { children, href, active } = props;
  return (
    <Link to={href} className={`nav-item nav-link ${active}`}>
      {children}
    </Link>
  );
};

export default LinkC;
