/* eslint-disable jsx-a11y/img-redundant-alt */

import { Helmet } from "react-helmet";
import HeaderCB from "./Header";
import MenuC from "./Menu";
import FooterCB from "./Footer";

/* eslint-disable jsx-a11y/anchor-is-valid */
const LayoutBackend = (props: any) => {
  const { children } = props;
  return (
    <>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <Helmet>
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700&display=fallback"
        />
        <link
          rel="stylesheet"
          href="/backend/plugins/fontawesome-free/css/all.min.css"
        />
        <link
          rel="stylesheet"
          href="https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css"
        />
        <link
          rel="stylesheet"
          href="/backend/plugins/tempusdominus-bootstrap-4/css/tempusdominus-bootstrap-4.min.css"
        />
        <link rel="stylesheet" href="/backend/dist/css/adminlte.min.css" />
        <link
          rel="stylesheet"
          href="/backend/plugins/overlayScrollbars/css/OverlayScrollbars.min.css"
        />
      </Helmet>
      <div className="wrapper">
        <HeaderCB />
        <MenuC />
        <div className="content-wrapper">
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>Sistem Informasi HMJ IT</h1>
                </div>
              </div>
            </div>
          </section>
          <div className="content">
            <div className="container-fluid">{children}</div>
          </div>
        </div>
        <FooterCB />
      </div>
      <Helmet>
        <script src="/backend/plugins/overlayScrollbars/js/jquery.overlayScrollbars.min.js"></script>
        <script src="/backend/dist/js/adminlte.js"></script>
      </Helmet>
    </>
  );
};

export default LayoutBackend;
