import { Link } from "react-router-dom";
import LayoutFrontend from "../component/frontend/MainLayout";
import { useEffect } from "react";

const DokumentasiPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Halaman Dokumentasi";
  });
  return (
    <>
      <LayoutFrontend>
        <div className="container-fluid bg-breadcrumb">
          <div className="container text-center py-5" style={{ maxWidth: 900 }}>
            <h4
              className="text-white display-4 mb-4 wow fadeInDown"
              data-wow-delay="0.1s"
            >
              Dokumentasi
            </h4>
            <ol
              className="breadcrumb d-flex justify-content-center mb-0 wow fadeInDown"
              data-wow-delay="0.3s"
            >
              <li className="breadcrumb-item">
                <a href="index.html">Beranda</a>
              </li>
              <li className="breadcrumb-item active text-primary">
                Dokumentasi
              </li>
            </ol>
          </div>
        </div>

        <div className="container-fluid feature bg-light pb-5 pt-5">
          <div className="container pb-5">
            <div
              className="text-center mx-auto pb-5 wow fadeInUp"
              data-wow-delay="0.2s"
              style={{ maxWidth: 800 }}
            >
              <p className="mb-0">
                Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                Tenetur adipisci facilis cupiditate recusandae aperiam
                temporibus corporis itaque quis facere, numquam, ad culpa
                deserunt sint dolorem autem obcaecati, ipsam mollitia hic.
              </p>
            </div>
            <div className="row g-4">
              <div
                className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp"
                data-wow-delay="0.2s"
              >
                <div className="feature-item p-4 pt-0">
                  <div className="feature-icon p-4 mb-4">
                    <i className="far fa-handshake fa-3x" />
                  </div>
                  <h4 className="mb-4">Kegiatan</h4>
                  <p className="mb-4">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea
                    hic laborum odit pariatur...
                  </p>
                  <Link
                    className="btn btn-primary rounded-pill py-2 px-4"
                    to={"/kegiatan"}
                  >
                    Selengkapnya
                  </Link>
                </div>
              </div>
              <div
                className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp"
                data-wow-delay="0.4s"
              >
                <div className="feature-item p-4 pt-0">
                  <div className="feature-icon p-4 mb-4">
                    <i className="fa fa-dollar-sign fa-3x" />
                  </div>
                  <h4 className="mb-4">Archive</h4>
                  <p className="mb-4">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea
                    hic laborum odit pariatur...
                  </p>
                  <Link
                    className="btn btn-primary rounded-pill py-2 px-4"
                    to={"/archive"}
                  >
                    Selengkapnya
                  </Link>
                </div>
              </div>
              {/* <div
                className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp"
                data-wow-delay="0.6s"
              >
                <div className="feature-item p-4 pt-0">
                  <div className="feature-icon p-4 mb-4">
                    <i className="fa fa-bullseye fa-3x" />
                  </div>
                  <h4 className="mb-4">Flexible Plans</h4>
                  <p className="mb-4">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea
                    hic laborum odit pariatur...
                  </p>
                  <a
                    className="btn btn-primary rounded-pill py-2 px-4"
                    href="#"
                  >
                    Learn More
                  </a>
                </div>
              </div>
              <div
                className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp"
                data-wow-delay="0.8s"
              >
                <div className="feature-item p-4 pt-0">
                  <div className="feature-icon p-4 mb-4">
                    <i className="fa fa-headphones fa-3x" />
                  </div>
                  <h4 className="mb-4">24/7 Fast Support</h4>
                  <p className="mb-4">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea
                    hic laborum odit pariatur...
                  </p>
                  <a
                    className="btn btn-primary rounded-pill py-2 px-4"
                    href="#"
                  >
                    Learn More
                  </a>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </LayoutFrontend>
    </>
  );
};
export default DokumentasiPage;
