// AnggotaController.ts
export interface Anggota {
  id_ang: number;
  nim: string;
  nama: string;
  divisi: string;
  gambar: string;
  tempat: string;
  tgl: string;
  motto: string;
  jk: number;
  no_hp: number;
  status: string;
  created_at: string;
}

// Fungsi untuk mengambil data dari API
export const fetchAnggotaResult = async (): Promise<Anggota[]> => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/anggota`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  });
  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }
  const data: Anggota[] = await response.json();
  return data;
};

export const fetchPublicAnggota = async (): Promise<Anggota[]> => {
  const response = await fetch(
    `https://hmj.goitech.info/data/api/public_anggota/apihmjit@123`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );
  console.log(response);
  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }
  const data: Anggota[] = await response.json();
  return data;
};

// Fungsi untuk memfilter data berdasarkan pencarian
export const filterData = (data: Anggota[], search: string): Anggota[] => {
  return data.filter(
    (anggota) =>
      anggota.nama.toLowerCase().includes(search.toLowerCase()) ||
      anggota.nim.toLowerCase().includes(search.toLowerCase())
  );
};

// Fungsi untuk menghitung total halaman
export const getTotalPages = (
  filteredData: Anggota[],
  showCount: number
): number => {
  return Math.ceil(filteredData.length / showCount);
};

// Fungsi untuk mendapatkan data berdasarkan halaman saat ini
export const getPaginatedData = (
  filteredData: Anggota[],
  currentPage: number,
  showCount: number
): Anggota[] => {
  return filteredData.slice(
    (currentPage - 1) * showCount,
    currentPage * showCount
  );
};

// controller.ts

export const fetchFormData = async (id: string) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/anggota/${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    );
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching form data:", error);
    throw error;
  }
};

export const validateFormData = (formData: any) => {
  const errors: any = {};

  if (!formData.nim) errors.nim = "NIM harus diisi!";
  if (!formData.nama) errors.nama = "Nama harus diisi!";
  if (!formData.tempat) errors.tempat = "Tempat lahir harus diisi!";
  if (!formData.tgl) errors.tgl = "Tanggal lahir harus diisi!";
  if (!formData.jk) errors.jk = "Jenis kelamin harus diisi!";
  if (!formData.status) errors.status = "Status harus diisi!";
  if (!formData.motto) errors.motto = "Motto harus diisi!";
  if (!formData.no_hp) errors.no_hp = "Nomor HP harus diisi!";
  return errors;
};

export const submitFormData = async (formData: any, isEdit: boolean) => {
  const url = isEdit
    ? `${process.env.REACT_APP_API_URL}/update_anggota`
    : `${process.env.REACT_APP_API_URL}/anggota`;

  try {
    const body = new FormData();
    for (const key in formData) {
      if (key === "gambar" && typeof formData[key] === "string") {
        // Skip appending if the file is just a path string
        continue;
      }
      body.append(key, formData[key]);
    }

    const response = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: body,
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
  } catch (error) {
    console.error("Error submitting form data:", error);
    throw error;
  }
};

export const submitRegisterData = async (formData: any) => {
  const url = `${process.env.REACT_APP_API_URL}/daftar`;

  try {
    // Check if formData is already FormData, else convert it
    const body = formData instanceof FormData ? formData : new FormData();
    if (!(formData instanceof FormData)) {
      for (const key in formData) {
        body.append(key, formData[key]);
      }
    }

    const response = await fetch(url, {
      method: "POST",
      headers: {
        // "Content-Type" should not be set for FormData
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: body,
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
  } catch (error) {
    console.error("Error submitting form data:", error);
    throw error;
  }
};
