// AnggotaController.ts
export interface Artikel {
  id_artikel: number;
  judul: string;
  slug: string;
  artikel: string;
  excerpt: string;
  nama: string;
  status: string;
  tanggal: string;
  penulis: string;
  gambar: string;
}

// Fungsi untuk mengambil data dari API
export const fetchArtikel = async (): Promise<Artikel[]> => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/artikel`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  });
  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }
  const data: Artikel[] = await response.json();
  return data;
};

export const fetchBlog = async (): Promise<Artikel[]> => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/blog_public`, {
    method: "POST",
    body: JSON.stringify({
      token: process.env.REACT_APP_TOKEN,
    }),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }
  const data: Artikel[] = await response.json();
  return data;
};

// Fungsi untuk memfilter data berdasarkan pencarian
export const filterData = (data: Artikel[], search: string): Artikel[] => {
  return data.filter((artikel) => {
    const nama = artikel.nama ? artikel.nama.toLowerCase() : "";
    const judul = artikel.judul ? artikel.judul.toLowerCase() : "";
    const lowerCaseSearch = search.toLowerCase();

    return nama.includes(lowerCaseSearch) || judul.includes(lowerCaseSearch);
  });
};

// Fungsi untuk menghitung total halaman
export const getTotalPages = (
  filteredData: Artikel[],
  showCount: number
): number => {
  return Math.ceil(filteredData.length / showCount);
};

// Fungsi untuk mendapatkan data berdasarkan halaman saat ini
export const getPaginatedData = (
  filteredData: Artikel[],
  currentPage: number,
  showCount: number
): Artikel[] => {
  return filteredData.slice(
    (currentPage - 1) * showCount,
    currentPage * showCount
  );
};

// controller.ts

export const fetchFormData = async (id: string) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/artikel/${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    );
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching form data:", error);
    throw error;
  }
};

export const fetchDetail = async (slug: string) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/detailblog`,
      {
        method: "POST",
        body: JSON.stringify({
          slug: slug,
          token: process.env.REACT_APP_TOKEN,
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching form data:", error);
    throw error;
  }
};

export const validateFormData = (formData: any) => {
  const errors: any = {};

  if (!formData.judul) errors.judul = "Judul harus diisi!";
  if (!formData.kategori_id) errors.kategori_id = "Kategori harus diisi!";
  if (!formData.excerpt) errors.excerpt = "Kutipan harus diisi!";
  if (!formData.artikel) errors.artikel = "Artikel harus diisi!";
  if (!formData.status) errors.status = "Status harus diisi!";

  return errors;
};

export const submitFormData = async (formData: any, isEdit: boolean) => {
  const url = isEdit
    ? `${process.env.REACT_APP_API_URL}/update_artikel`
    : `${process.env.REACT_APP_API_URL}/artikel`;

  try {
    // Check if formData is already FormData, else convert it
    const body = formData instanceof FormData ? formData : new FormData();
    if (!(formData instanceof FormData)) {
      for (const key in formData) {
        body.append(key, formData[key]);
      }
    }

    const response = await fetch(url, {
      method: "POST",
      headers: {
        // "Content-Type" should not be set for FormData
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: body,
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
  } catch (error) {
    console.error("Error submitting form data:", error);
    throw error;
  }
};

export const submitStatistik = async (formData: any) => {
  const url = `${process.env.REACT_APP_API_URL}/statistik`;

  try {
    // Convert formData to FormData if it's not already
    const body = formData instanceof FormData ? formData : new FormData();
    if (!(formData instanceof FormData)) {
      Object.entries(formData).forEach(([key, value]) => {
        body.append(key, value as string | Blob);
      });
    }

    const response = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        ContentType: "application/json",
      },
      body: body,
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
  } catch (error) {
    console.error("Error submitting form data:", error);
    throw error;
  }
};

export const submitCari = async (formData: any) => {
  const url = `${process.env.REACT_APP_API_URL}/cari?keyword=${formData.keyword}`;
  console.log(formData);
  try {
    const response = await fetch(url, {
      method: "GET",
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error submitting form data:", error);
    throw error;
  }
};
