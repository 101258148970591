import React, { createContext, useContext, useState, ReactNode } from "react";

// Tipe untuk konteks
interface StateContextType {
  user: any; // Ganti `any` dengan tipe yang sesuai untuk pengguna
  token: string | null;
  setUser: (user: any) => void; // Ganti `any` dengan tipe yang sesuai untuk pengguna
  setToken: (token: string | null) => void;
}

// Inisialisasi konteks dengan nilai default
const stateContext = createContext<StateContextType>({
  user: null,
  token: null,
  setUser: () => {},
  setToken: () => {},
});

interface StateProviderProps {
  children: ReactNode;
}

export const StateProvider: React.FC<StateProviderProps> = ({ children }) => {
  const [user, setUser] = useState<any>(null); // Ganti `any` dengan tipe yang sesuai untuk pengguna
  const [token, setTokenState] = useState<string | null>(
    sessionStorage.getItem("token")
  );

  // Fungsi untuk mengatur token dan menyimpannya di sessionStorage
  const setToken = (token: string | null) => {
    setTokenState(token);
    if (token) {
      sessionStorage.setItem("token", token);
    } else {
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("id_user");
    }
  };

  return (
    <stateContext.Provider
      value={{
        user,
        token,
        setUser,
        setToken,
      }}
    >
      {children}
    </stateContext.Provider>
  );
};

// Kustom hook untuk menggunakan konteks
export const useStateContext = () => useContext(stateContext);
