/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/img-redundant-alt */
import { useEffect, useState } from "react";
import LayoutFrontend from "../component/frontend/MainLayout";
import { About, fetchKontak } from "../backend/tentang/Controller";
import {
  submitFormData,
  submitRegisterData,
  validateFormData,
} from "../backend/master/anggota/Controller";
import { Navigate, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
const RegisterPage = () => {
  const [kontak, setKontak] = useState<About>();
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [message, setMessage] = useState("");
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    nama: "",
    nim: "",
    tempat: "",
    tgl: "",
    status: 1,
    jk: "",
    motto: "",
    divisi: "-",
    no_hp: "",
    gambar: new File([""], ""),
  });
  const loadData = async () => {
    try {
      const data = await fetchKontak();
      setKontak(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value, files } = e.target as HTMLInputElement;
    if (files && files.length > 0) {
      const file = files[0];
      if (file.size > 2 * 1024 * 1024) {
        // Memeriksa apakah ukuran file melebihi 2MB
        alert("Ukuran file tidak boleh melebihi 2MB!");
        return; // Menghentikan pemrosesan lebih lanjut
      }
      // Membuat URL sementara untuk prapratampilan gambar
      const imageUrl = URL.createObjectURL(file);
      setImagePreview(imageUrl);
      setFormData((prevData) => ({
        ...prevData,
        [name]: files[0],
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    const validationErrors = validateFormData(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setIsLoading(false);
      return;
    }
    try {
      await submitRegisterData(formData);
      setMessage(
        "Anda berhasil mendaftar silahkan tunggu informasi selanjutnya"
      );
      Swal.fire({
        title: "Berhasil!",
        html: "Anda berhasil mendaftar,silahkan  bergabung ke grup whatsapp kami ",
        icon: "success",
        confirmButtonText:
          "<a href='https://chat.whatsapp.com/K5HqhVW0IiL2IlMtXvN1Ju' style='color: white; font-weight: bold'>Gabung Whatsapp</a>",
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadData();
    document.title = "Halaman Daftar";
  });

  return (
    <>
      <LayoutFrontend>
        <>
          <div className="container-fluid contact bg-light py-4">
            <div className="container py-1">
              <div
                className="text-center mx-auto pb-5 wow fadeInUp"
                data-wow-delay="0.2s"
                style={{ maxWidth: 800 }}
              >
                <h1 className="display-4 mb-4">Form Pendaftaran</h1>
              </div>
              <div className="row g-5">
                <div className="col-xl-6 wow fadeInLeft" data-wow-delay="0.2s">
                  <div className="contact-img d-flex justify-content-center">
                    <div className="contact-img-inner">
                      <img
                        src="/ali.png"
                        className="img-fluid w-100"
                        alt="Image"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 wow fadeInRight" data-wow-delay="0.4s">
                  <div>
                    {message && (
                      <div className="alert alert-success" role="alert">
                        {message}
                      </div>
                    )}
                    <form onSubmit={handleSubmit}>
                      <div className="row g-3">
                        <div className="col-lg-12 col-xl-6">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control border-0"
                              id="nama"
                              name="nama"
                              placeholder="Nama Lengkap"
                              onChange={handleChange}
                            />
                            {errors.nama && (
                              <p className="text-danger">{errors.nama}</p>
                            )}
                            <label htmlFor="nama">Nama Lengkap</label>
                          </div>
                        </div>
                        <div className="col-lg-12 col-xl-6">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control border-0"
                              id="tempat"
                              name="tempat"
                              placeholder="Tempat Lahir"
                              onChange={handleChange}
                            />
                            {errors.tempat && (
                              <p className="text-danger">{errors.tempat}</p>
                            )}
                            <label htmlFor="nama">Tempat Lahir</label>
                          </div>
                        </div>
                        <div className="col-lg-12 col-xl-6">
                          <div className="form-floating">
                            <input
                              type="date"
                              className="form-control border-0"
                              id="tgl"
                              name="tgl"
                              placeholder="Tanggal Lahir"
                              onChange={handleChange}
                            />
                            {errors.tgl && (
                              <p className="text-danger">{errors.tgl}</p>
                            )}
                            <label htmlFor="nama">Tanggal Lahir</label>
                          </div>
                        </div>
                        <div className="col-lg-12 col-xl-6">
                          <div className="form-floating">
                            <select
                              className="form-control border-0"
                              id="jk"
                              name="jk"
                              onChange={handleChange}
                              style={{ backgroundColor: "white" }}
                            >
                              <option value="">-gender-</option>
                              <option value="1">Laki-laki</option>
                              <option value="2">Perempuan</option>
                            </select>
                            {errors.jk && (
                              <p className="text-danger">{errors.jk}</p>
                            )}
                            <label htmlFor="nama">Gender</label>
                          </div>
                        </div>
                        <div className="col-lg-12 col-xl-6">
                          <div className="form-floating">
                            <input
                              type="number"
                              className="form-control border-0"
                              id="nim"
                              name="nim"
                              placeholder="NIM"
                              onChange={handleChange}
                            />
                            {errors.nim && (
                              <p className="text-danger">{errors.nim}</p>
                            )}
                            <label htmlFor="nim">NIM</label>
                          </div>
                        </div>

                        <div className="col-lg-12 col-xl-6">
                          <div className="form-floating">
                            <input
                              type="number"
                              className="form-control border-0"
                              id="no_hp"
                              name="no_hp"
                              placeholder="no_hp"
                              onChange={handleChange}
                            />
                            {errors.no_hp && (
                              <p className="text-danger">{errors.no_hp}</p>
                            )}
                            <label htmlFor="nim">Nomer HP</label>
                          </div>
                        </div>
                        <div className="col-lg-12 col-xl-12">
                          <label htmlFor="nama">Foto Formal 3x4</label>
                          <input
                            className="form-control border-0"
                            type="file"
                            id="gambar"
                            name="gambar"
                            onChange={handleChange}
                            style={{ backgroundColor: "white" }}
                          />
                        </div>
                        <div className="col-12">
                          <div className="form-floating">
                            <textarea
                              className="form-control border-0"
                              placeholder="Motto Hidup"
                              id="motto"
                              name="motto"
                              style={{ height: 120 }}
                              onChange={handleChange}
                            />
                            {errors.nama && (
                              <p className="text-danger">{errors.motto}</p>
                            )}
                            <label htmlFor="message">Motto</label>
                          </div>
                        </div>
                        <div className="col-12">
                          <button
                            type="submit"
                            className="btn btn-primary w-100 py-3"
                          >
                            {" "}
                            {isLoading ? "Loading..." : "DAFTAR"}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </LayoutFrontend>
    </>
  );
};

export default RegisterPage;
