import { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { fetchFormData, validateFormData, submitFormData } from "./Controller";
import LayoutBackend from "../../component/backend/Layout";
import FormC from "../../component/backend/Form";
import { useStateContext } from "../../middleware";
import InputC from "../../component/backend/Input";

const AksiVideo = () => {
  const { id } = useParams();
  const isEdit = Boolean(id);
  const navigate = useNavigate();
  const { token } = useStateContext();
  const [formData, setFormData] = useState({
    judul: "",
    des: "",
    link: "",
    status: "",
  });
  const [errors, setErrors] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    document.title = isEdit ? "Edit Video" : "Tambah Video";
    if (id) {
      fetchFormData(id)
        .then((data) => setFormData(data))
        .catch((error) => console.error("Error:", error));
    }
  }, [id, isEdit]);

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target as HTMLInputElement;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    const validationErrors = validateFormData(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setIsLoading(false);
      return;
    }

    try {
      await submitFormData(formData, isEdit);
      navigate("/video", {
        state: {
          message: isEdit
            ? "Data berhasil diperbarui!"
            : "Data berhasil ditambahkan!",
        },
      });
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  if (!token) {
    return <Navigate to="/login" />;
  }

  return (
    <LayoutBackend>
      <FormC
        aksi={handleSubmit}
        formTitle={isEdit ? `Edit Video` : `Tambah Video`}
        buttonTitle={isEdit ? `Update` : `Simpan`}
        buttonType={`submit`}
        hrefTitle={`Kembali`}
        href={`/video`}
        loading={isLoading}
        encType={`multipart/form-data`}
      >
        <div className="col-6">
          <InputC
            label="Judul"
            name="judul"
            input_id="judul"
            input_type="text"
            value={formData.judul}
            onChange={handleChange}
            placeholder="Judul Video"
          />
          <span className="text-danger">{errors.judul}</span>
        </div>
        <div className="col-6">
          <InputC
            label="Deskripsi"
            name="des"
            input_id="des"
            input_type="text"
            value={formData.des}
            onChange={handleChange}
            placeholder="deskripsi video"
          />
          <span className="text-danger">{errors.des}</span>
        </div>
        <div className="col-6">
          <InputC
            label="Link"
            name="link"
            input_id="link"
            input_type="text"
            value={formData.link}
            onChange={handleChange}
            placeholder="link video"
          />
          <span className="text-danger">{errors.link}</span>
        </div>
        <div className="col-6">
          <label htmlFor="status">Status</label>
          <select
            name="status"
            id="status"
            className="form-control"
            value={formData.status}
            onChange={handleChange}
          >
            <option value="">-pilih-</option>
            <option value="1">Aktiv</option>
            <option value="2">Tidak</option>
          </select>
          <span className="text-danger">{errors.status}</span>
        </div>
      </FormC>
    </LayoutBackend>
  );
};

export default AksiVideo;
