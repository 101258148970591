const FooterCB = () => {
  return (
    <>
      <footer className="main-footer">
        <strong>
          Copyright © 2024 <a href="https://adminlte.io">wwww.hmjit.co.id</a>.
        </strong>
        All rights reserved.
        <div className="float-right d-none d-sm-inline-block">
          <b>Version</b> 3.2.0
        </div>
      </footer>
      <aside className="control-sidebar control-sidebar-dark"></aside>
    </>
  );
};

export default FooterCB;
