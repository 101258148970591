import { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import FormC from "../../../component/backend/Form";
import InputC from "../../../component/backend/Input";
import LayoutBackend from "../../../component/backend/Layout";
import { fetchFormData, validateFormData, submitFormData } from "./Controller";
import { useStateContext } from "../../../middleware";

const AksiAnggota = () => {
  const { id } = useParams();
  const isEdit = Boolean(id);
  const navigate = useNavigate();
  const { token } = useStateContext();
  const [formData, setFormData] = useState({
    nim: "",
    nama: "",
    tempat: "",
    tgl: "",
    jk: "",
    status: "",
    motto: "",
    divisi: "",
    no_hp: "",
    gambar: new File([], ""),
  });
  const [errors, setErrors] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  useEffect(() => {
    document.title = isEdit ? "Edit Anggota" : "Tambah Anggota";
    if (id) {
      fetchFormData(id)
        .then((data) => setFormData(data))
        .catch((error) => console.error("Error:", error));
    }
  }, [id, isEdit]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value, files } = e.target as HTMLInputElement;
    if (files && files.length > 0) {
      const file = files[0];
      if (file.size > 2 * 1024 * 1024) {
        // Memeriksa apakah ukuran file melebihi 2MB
        alert("Ukuran file tidak boleh melebihi 2MB!");
        return; // Menghentikan pemrosesan lebih lanjut
      }
      // Membuat URL sementara untuk prapratampilan gambar
      const imageUrl = URL.createObjectURL(file);
      setImagePreview(imageUrl);
      setFormData((prevData) => ({
        ...prevData,
        [name]: files[0],
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    const validationErrors = validateFormData(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setIsLoading(false);
      return;
    }
    console.log(formData);
    try {
      await submitFormData(formData, isEdit);
      navigate("/anggota", {
        state: {
          message: isEdit
            ? "Data berhasil diperbarui!"
            : "Data berhasil ditambahkan!",
        },
      });
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };
  if (!token) {
    return <Navigate to="/login" />;
  }
  return (
    <LayoutBackend>
      <FormC
        aksi={handleSubmit}
        formTitle={isEdit ? `Edit Anggota` : `Tambah Anggota`}
        buttonTitle={isEdit ? `Update` : `Simpan`}
        buttonType={`submit`}
        hrefTitle={`Kembali`}
        href={`/anggota`}
        loading={isLoading}
      >
        <div className="col-6">
          <InputC
            label={`NIM`}
            input_type={`number`}
            input_id={`nim`}
            name={"nim"}
            placeholder={`Nomor Induk Mahasiswa`}
            value={formData.nim}
            onChange={handleChange}
          />
          <span className="text-danger">{errors.nim}</span>
        </div>
        <div className="col-6">
          <InputC
            label={`Nama`}
            input_type={`text`}
            input_id={`nama`}
            name={"nama"}
            placeholder={`Nama Lengkap`}
            value={formData.nama}
            onChange={handleChange}
          />
          <span className="text-danger">{errors.nama}</span>
        </div>
        <div className="col-6">
          <InputC
            label={`Tempat Lahir`}
            input_type={`text`}
            input_id={`tempat`}
            name={"tempat"}
            placeholder={`Tempat Lahir`}
            value={formData.tempat}
            onChange={handleChange}
          />
          <span className="text-danger">{errors.tempat}</span>
        </div>
        <div className="col-6">
          <InputC
            label={`Tanggal Lahir`}
            input_type={`date`}
            input_id={`tgl`}
            name={"tgl"}
            value={formData.tgl}
            onChange={handleChange}
          />
          <span className="text-danger">{errors.tgl}</span>
        </div>
        <div className="col-6">
          <div className="form-group">
            <label htmlFor="jk">Jenis Kelamin</label>
            <select
              name="jk"
              id="jk"
              className="form-control"
              value={formData.jk}
              onChange={handleChange}
            >
              <option value="">-pilih-</option>
              <option value="1">Laki-Laki</option>
              <option value="2">Perempuan</option>
            </select>
            <span className="text-danger">{errors.jk}</span>
          </div>
        </div>
        <div className="col-6">
          <InputC
            label={`Nomor Hp`}
            input_type={`number`}
            input_id={`no_hp`}
            name={"no_hp"}
            placeholder={`Nomor Hp`}
            value={formData.no_hp}
            onChange={handleChange}
          />
          <span className="text-danger">{errors.no_hp}</span>
        </div>
        <div className="col-6">
          <label htmlFor="status">Status</label>
          <select
            name="status"
            id="status"
            className="form-control"
            value={formData.status}
            onChange={handleChange}
          >
            <option value="">-pilih-</option>
            <option value="1">Aktiv</option>
            <option value="2">Tidak</option>
          </select>
          <span className="text-danger">{errors.status}</span>
        </div>
        <div className="col-6">
          <InputC
            label={`Motto Hidup`}
            input_type={`text`}
            input_id={`motto`}
            name={"motto"}
            placeholder={`Motto Hidup`}
            value={formData.motto}
            onChange={handleChange}
          />
          <span className="text-danger">{errors.motto}</span>
        </div>
        <div className="col-6">
          <label htmlFor="divisi">Divisi</label>
          <select
            name="divisi"
            id="divisi"
            className="form-control"
            value={formData.divisi}
            onChange={handleChange}
          >
            <option value="">-pilih-</option>
            <option value="Kominfo">Kominfo</option>
            <option value="Binekraf">Binekraf</option>
            <option value="Litbang">Litbang</option>
          </select>
          <span className="text-danger">{errors.divisi}</span>
        </div>
        <div className="col-6">
          <InputC
            label={`Upload Foto`}
            input_type={`file`}
            input_id={`gambar`}
            name={"gambar"}
            onChange={handleChange}
          />
          {isEdit ? (
            <img
              src={`${process.env.REACT_APP_API_IMAGE}/storage/${formData.gambar}`}
              className="img-fluid"
              width={100}
              alt=""
            />
          ) : (
            imagePreview && (
              <img
                src={imagePreview!}
                className="img-fluid"
                width={100}
                alt="Preview"
              />
            )
          )}
        </div>
      </FormC>
    </LayoutBackend>
  );
};

export default AksiAnggota;
