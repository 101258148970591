// AnggotaController.ts
export interface Faq {
  id_faq: number;
  soal1: string;
  jawab1: string;
}

// Fungsi untuk mengambil data dari API
export const fetchFaq = async (): Promise<Faq[]> => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/faq`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  });
  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }
  const data: Faq[] = await response.json();
  return data;
};
// Fungsi untuk memfilter data berdasarkan pencarian
export const filterData = (data: Faq[], search: string): Faq[] => {
  return data.filter((pengurus) =>
    pengurus.soal1.toLowerCase().includes(search.toLowerCase())
  );
};

// Fungsi untuk menghitung total halaman
export const getTotalPages = (
  filteredData: Faq[],
  showCount: number
): number => {
  return Math.ceil(filteredData.length / showCount);
};

// Fungsi untuk mendapatkan data berdasarkan halaman saat ini
export const getPaginatedData = (
  filteredData: Faq[],
  currentPage: number,
  showCount: number
): Faq[] => {
  return filteredData.slice(
    (currentPage - 1) * showCount,
    currentPage * showCount
  );
};

// controller.ts

export const fetchFormData = async (id: string) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/faq/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching form data:", error);
    throw error;
  }
};

export const validateFormData = (formData: any) => {
  const errors: any = {};

  if (!formData.soal1) errors.soal1 = "Pertanyaan harus diisi!";
  if (!formData.jawab1) errors.jawab1 = "Jawaban harus diisi!";

  return errors;
};

export const submitFormData = async (formData: any, isEdit: boolean) => {
  const url = isEdit
    ? `${process.env.REACT_APP_API_URL}/update_faq`
    : `${process.env.REACT_APP_API_URL}/faq`;

  try {
    //cek apakah tipe formData adalah FormData, jika bukan, ubah menjadi FormData
    const body = formData instanceof FormData ? formData : new FormData();
    if (!(formData instanceof FormData)) {
      for (const key in formData) {
        body.append(key, formData[key]);
      }
    }

    const response = await fetch(url, {
      method: "POST",
      headers: {
        // "Content-Type" should not be set for FormData
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: body,
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
  } catch (error) {
    console.error("Error submitting form data:", error);
    throw error;
  }
};
