import { useEffect, useState } from "react";
import {
  fetchVideo,
  filterData,
  getPaginatedData,
  getTotalPages,
  Video,
} from "./Controller";
import { Link, useLocation } from "react-router-dom";
import LayoutBackend from "../../component/backend/Layout";
import TableC from "../../component/backend/Table";
import LinkC from "../../component/backend/Link";

const VideoPage = () => {
  const [video, setVideo] = useState<Video[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [search, setSearch] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [showCount, setShowCount] = useState<number>(10);
  const location = useLocation();
  const [message, setMessage] = useState(location.state?.message);
  useEffect(() => {
    document.title = "Data Video";
    try {
      const loadData = async () => {
        const data = await fetchVideo();
        setVideo(data);
      };
      loadData();
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }, []);
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setCurrentPage(1);
  };
  const handleShowCountChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setShowCount(Number(e.target.value));
    setCurrentPage(1);
  };

  const filteredData = filterData(video, search);
  const totalPages = getTotalPages(filteredData, showCount);
  const paginatedData = getPaginatedData(filteredData, currentPage, showCount);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Mengatur halaman sebelumnya
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleDelete = async (id: number) => {
    if (window.confirm("Apakah Anda yakin ingin menghapus data ini?")) {
      await deleteData(id);
    }
  };
  const deleteData = async (id: number) => {
    console.log(process.env.REACT_APP_API_URL);
    try {
      await fetch(`${process.env.REACT_APP_API_URL}/video/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });
      setVideo(video.filter((video) => video.id_video !== id));
      setMessage("Data Berhasil dihapus");
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };
  return (
    <>
      <LayoutBackend>
        {message && (
          <div className="alert alert-success">
            {" "}
            <i className="fas fa-check"></i> {message}
          </div>
        )}
        <TableC
          href={`/tambah_video`}
          titlehref={`Tambah`}
          title={`Data Video`}
        >
          <div className="table-responsive">
            <div className="col-sm-12">
              <div className="d-flex justify-content-between align-items-center">
                <div className="dataTables_length">
                  <label>
                    Show{" "}
                    <select
                      name="example_length"
                      aria-controls="example"
                      className="form-select form-select-sm"
                      value={showCount}
                      onChange={handleShowCountChange}
                    >
                      <option value={10}>10</option>
                      <option value={25}>25</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                    </select>
                  </label>
                </div>
                <div className="dataTables_filter">
                  <label className="w-100">
                    Search:
                    <input
                      type="search"
                      className="form-control form-control-sm"
                      placeholder=""
                      aria-controls="example"
                      value={search}
                      onChange={handleSearchChange}
                    />
                  </label>
                </div>
              </div>
            </div>
            {loading ? (
              <center>Loading.....</center>
            ) : (
              <>
                <table
                  id="example"
                  className="table table-striped"
                  style={{ width: "100%" }}
                >
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Judul</th>
                      <th>Video</th>
                      <th>Aksi</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paginatedData.map((video, index) => (
                      <tr key={video.id_video}>
                        <td>{index + 1}</td>
                        <td>{video.judul}</td>
                        <td>
                          <LinkC color={`primary`} href={video.link}>
                            <i className="fa fa-play"></i>
                          </LinkC>
                        </td>
                        <td>
                          <LinkC
                            color={`warning`}
                            href={`/video/${video.id_video}`}
                          >
                            <i className="fa fa-edit"></i>
                          </LinkC>
                          <LinkC
                            color={`danger`}
                            onClick={() => handleDelete(video.id_video)}
                          >
                            <i className="fa fa-trash"></i>
                          </LinkC>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div
                  id="example_paginate"
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <ul className="pagination">
                    <li
                      className={`paginate_button page-item previous ${
                        currentPage === 1 ? "disabled" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={handlePrevPage}
                        disabled={currentPage === 1}
                      >
                        Prev
                      </button>
                    </li>
                    {[...Array(totalPages)].map((_, pageIndex) => (
                      <li
                        key={pageIndex}
                        className={`paginate_button page-item ${
                          currentPage === pageIndex + 1 ? "active" : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => setCurrentPage(pageIndex + 1)}
                        >
                          {pageIndex + 1}
                        </button>
                      </li>
                    ))}
                    <li
                      className={`paginate_button page-item next ${
                        currentPage === totalPages ? "disabled" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages}
                      >
                        Next
                      </button>
                    </li>
                  </ul>
                </div>
              </>
            )}
          </div>
        </TableC>
      </LayoutBackend>
    </>
  );
};

export default VideoPage;
