import React, { useEffect, useState } from "react";
import LayoutFrontend from "../component/frontend/MainLayout";
import {
  Artikel,
  fetchArtikel,
  fetchDetail,
  submitStatistik,
} from "../backend/blog/artikel/Controller";
import { fetchKategori, Kategori } from "../backend/blog/kategori/Controller";
import { Link, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { time } from "console";

const DetailBlog = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [detail, setDetail] = useState<Artikel>();
  const [artikel, setArtikel] = useState<Artikel[]>([]);
  const [kategori, setKategori] = useState<Kategori[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [search, setSearch] = useState<string>("");
  const location = useLocation();
  const articleId = window.location.pathname.split("/")[2];
  useEffect(() => {
    dataApiArtikel();
    dataApiDetail();
    dataApiKategori();
  }, [location]);
  const dataApiArtikel = async () => {
    setIsLoading(true);
    try {
      const artikelResult = await fetchArtikel();
      setArtikel(artikelResult);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  const dataApiKategori = async () => {
    setIsLoading(true);
    try {
      const kategoriResult = await fetchKategori();
      setKategori(kategoriResult);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  const dataApiDetail = async () => {
    setIsLoading(true);
    try {
      const detailResult = await fetchDetail(articleId);
      setDetail(detailResult);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  const detectOS = () => {
    const userAgent = window.navigator.userAgent;
    const platform = window.navigator.platform;
    const macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"];
    const windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"];
    const iosPlatforms = ["iPhone", "iPad", "iPod"];
    let os = "Unknown OS";

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = "Mac OS";
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = "iOS";
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = "Windows";
    } else if (/Android/.test(userAgent)) {
      os = "Android";
    } else if (!os && /Linux/.test(platform)) {
      os = "Linux";
    }

    return os;
  };
  const handleStatistik = async () => {
    const os = detectOS();
    const data = {
      lokasi: os,
      share: 1,
      artikel_id: articleId,
    };

    try {
      await submitStatistik(data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      handleStatistik();
    }, 10000); // Delay of 3 seconds
    console.log(detail?.id_artikel);
    return () => clearTimeout(timer);
  }, [location]);
  const truncateText = (text: string, maxLength: number) => {
    if (!text) return "";
    return text.length > maxLength
      ? text.substring(0, maxLength) + "..."
      : text;
  };
  const handleCari = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = {
      keyword: search,
    };
    try {
      window.location.href = `/cari?${new URLSearchParams(data).toString()}`;
    } catch (error) {
      console.error("Error:", error);
    }
  };
  return (
    <LayoutFrontend>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Detail Blog</title>
        <meta
          name="description"
          content={detail?.judul ?? "Blog detail page"}
        />
        <meta property="og:title" content={detail?.judul ?? "Blog Detail"} />
        <meta
          property="og:description"
          content={truncateText(detail?.artikel ?? "", 150)}
        />
        <meta
          property="og:image"
          content={`${process.env.REACT_APP_API_IMAGE}/storage/${detail?.gambar}`}
        />
        <meta property="og:url" content={window.location.href} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={detail?.judul ?? "Blog Detail"} />
        <meta
          name="twitter:description"
          content={truncateText(detail?.artikel ?? "", 150)}
        />
        <meta
          name="twitter:image"
          content={`${process.env.REACT_APP_API_IMAGE}/storage/${detail?.gambar}`}
        />
      </Helmet>
      <div className="container-fluid feature bg-light py-1">
        <div className="container py-5">
          <div className="row">
            <div className="col-lg-8">
              <div className="card mb-4">
                <div className="card-body">
                  <header className="mb-4">
                    <h2 className="font-weight-bold">
                      {isLoading ? "Loading..." : detail?.judul}
                    </h2>
                    <p className="text-muted">
                      <i className="fa fa-user"></i>{" "}
                      {isLoading ? "Loading..." : detail?.penulis} |{" "}
                      <i className="fa fa-calendar"></i>{" "}
                      {isLoading ? "Loading..." : detail?.tanggal} |{" "}
                      <i className="fa fa-folder-open"></i>{" "}
                      {isLoading ? "Loading..." : detail?.nama}
                    </p>
                  </header>

                  <div className="mb-4 text-center">
                    {isLoading ? (
                      <img
                        src={`https://cdn.vectorstock.com/i/500p/65/30/default-image-icon-missing-picture-page-vector-40546530.jpg`}
                        className="img-fluid rounded"
                        style={{
                          width: "100%",
                          maxHeight: "500px",
                          objectFit: "cover",
                        }}
                        alt="Blog Banner"
                      />
                    ) : (
                      <img
                        src={`${process.env.REACT_APP_API_IMAGE}/storage/${detail?.gambar}`}
                        className="img-fluid rounded"
                        style={{
                          width: "100%",
                          maxHeight: "500px",
                          objectFit: "cover",
                        }}
                        alt="Blog Banner"
                      />
                    )}
                  </div>

                  <article className="mb-5">
                    {isLoading ? (
                      <p>Loading...</p>
                    ) : error ? (
                      <p className="text-danger">Error: {error}</p>
                    ) : (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: detail?.artikel ?? "",
                        }}
                      ></div>
                    )}
                  </article>
                </div>
              </div>
            </div>

            <aside className="col-lg-4">
              <div className="card mb-4">
                <div className="card-body">
                  <form onSubmit={handleCari}>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        name="keyword"
                        onChange={(e) => setSearch(e.target.value)}
                      />
                      <div className="input-group-append">
                        <button className="btn btn-primary" type="submit">
                          <i className="fa fa-search"></i>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <div className="card mb-4">
                <div className="card-body">
                  <h4 className="font-weight-bold">Postingan Terbaru</h4>
                  <ul className="list-unstyled">
                    {isLoading ? (
                      <p>Loading...</p>
                    ) : (
                      artikel.map((artikel) => (
                        <li className="media mb-3" key={artikel.id_artikel}>
                          <div className="row">
                            <div className="col-3">
                              <img
                                src={`${process.env.REACT_APP_API_IMAGE}/storage/${artikel.gambar}`}
                                className="mr-3 rounded"
                                alt="Post Thumbnail"
                                style={{
                                  width: "90px",
                                  height: "80px",
                                  objectFit: "cover",
                                }}
                              />
                            </div>
                            <div className="col-9">
                              <div className="media-body">
                                <h5 className="mt-0 mb-1">
                                  <a
                                    href={`/blog/${artikel.slug}`}
                                    className="text-dark"
                                  >
                                    {truncateText(artikel.judul, 20)}
                                  </a>
                                </h5>
                                <p>{truncateText(artikel.excerpt, 50)}</p>
                              </div>
                            </div>
                          </div>
                        </li>
                      ))
                    )}
                  </ul>
                </div>
              </div>

              <div className="card mb-4">
                <div className="card-body">
                  <h4 className="font-weight-bold">Kategori</h4>
                  <ul className="list-unstyled">
                    {isLoading ? (
                      <p>Loading...</p>
                    ) : (
                      kategori.map((kategori) => (
                        <li key={kategori.id}>
                          <Link
                            to={`/kategori/${kategori.nama}`}
                            className="d-block py-1 border-bottom"
                          >
                            {kategori.nama}
                          </Link>
                        </li>
                      ))
                    )}
                  </ul>
                </div>
              </div>
            </aside>
          </div>
        </div>
      </div>
    </LayoutFrontend>
  );
};

export default DetailBlog;
