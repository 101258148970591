import React, { useEffect, useState } from "react";
import { About, fetchKontak, submitFormData } from "./Controller";
import { Navigate, useLocation } from "react-router-dom";
import { useStateContext } from "../../middleware";
import LayoutBackend from "../../component/backend/Layout";
import FormC from "../../component/backend/Form";
import InputC from "../../component/backend/Input";
import CKEditorComponent from "../../component/backend/Ckeditor";

const TentangPage: React.FC = () => {
  const [data, setData] = useState<About | null>(null); // Adjust type accordingly
  const [loading, setLoading] = useState<boolean>(true);
  const location = useLocation();
  const [message, setMessage] = useState<string | null>(
    location.state?.message || null
  );
  const { token } = useStateContext();
  const [formData, setFormData] = useState({
    alamat: "",
    nomor: "",
    email: "",
    jam: "",
    twit: "",
    fby: "",
    ig: "",
    tiktok: "",
    des: "",
    user_id: sessionStorage.getItem("user"),
    gambar: null as File | null,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [imagePreview, setImagePreview] = useState<string | null>(null);

  useEffect(() => {
    document.title = "Tentang Kami";
    const loadData = async () => {
      try {
        const fetchedData = await fetchKontak();
        setData(fetchedData); // Assuming fetchData returns an object of type About
        if (fetchedData.gambar) {
          setImagePreview(
            `${process.env.REACT_APP_API_IMAGE}/storage/${fetchedData.gambar}`
          );
        }
        setFormData((prevData) => ({
          ...prevData,
          ...fetchedData,
          gambar: null, // Reset the file field
        }));
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, []);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value, files } = e.target as HTMLInputElement;
    if (files && files.length > 0) {
      const file = files[0];
      if (file.size > 2 * 1024 * 1024) {
        alert("Ukuran file tidak boleh melebihi 2MB!");
        return;
      }
      const imageUrl = URL.createObjectURL(file);
      setImagePreview(imageUrl);
      setFormData((prevData) => ({
        ...prevData,
        [name]: file,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleEditorChange = (data: string) => {
    setFormData((prevData) => ({
      ...prevData,
      des: data,
    }));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      await submitFormData(formData);
      setMessage("Data berhasil diperbarui!");
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  if (!token) {
    return <Navigate to="/login" />;
  }

  return (
    <LayoutBackend>
      {message && (
        <div className="alert alert-success">
          <i className="fas fa-check"></i> {message}
        </div>
      )}
      <FormC
        aksi={handleSubmit}
        formTitle="Tentang Kami"
        buttonTitle="Update"
        buttonType="submit"
        hrefTitle="Kembali"
        href="/media"
        loading={isLoading}
      >
        <div className="col-6">
          <InputC
            label="Alamat"
            input_type="text"
            input_id="alamat"
            name="alamat"
            value={formData.alamat}
            onChange={handleChange}
          />
        </div>
        <div className="col-6">
          <InputC
            label="Nomor"
            input_type="text"
            input_id="nomor"
            name="nomor"
            placeholder="Masukkan Nomor"
            value={formData.nomor}
            onChange={handleChange}
          />
        </div>
        <div className="col-6">
          <InputC
            label="Email"
            input_type="email"
            input_id="email"
            name="email"
            placeholder="Masukkan email"
            value={formData.email}
            onChange={handleChange}
          />
        </div>
        <div className="col-6">
          <InputC
            label="Jam"
            input_type="text"
            input_id="jam"
            name="jam"
            placeholder="Masukkan jam"
            value={formData.jam}
            onChange={handleChange}
          />
        </div>
        <div className="col-6">
          <InputC
            label="Twitter"
            input_type="text"
            input_id="twit"
            name="twit"
            value={formData.twit}
            onChange={handleChange}
          />
        </div>
        <div className="col-6">
          <InputC
            label="Instagram"
            input_type="text"
            input_id="ig"
            name="ig"
            value={formData.ig}
            onChange={handleChange}
          />
        </div>
        <div className="col-6">
          <InputC
            label="Facebook"
            input_type="text"
            input_id="fby"
            name="fby"
            value={formData.fby}
            onChange={handleChange}
          />
        </div>
        <div className="col-6">
          <InputC
            label="Tiktok"
            input_type="text"
            input_id="fby"
            name="fby"
            value={formData.fby}
            onChange={handleChange}
          />
        </div>
        <div className="col-12">
          <InputC
            label="Upload Logo"
            input_type="file"
            input_id="gambar"
            name="gambar"
            onChange={handleChange}
          />
          {imagePreview && (
            <img
              src={imagePreview}
              className="img-fluid"
              width={50}
              alt="Preview"
            />
          )}
        </div>
        <div className="col-12">
          <label htmlFor="artikel">Tentang Kami</label>
          <CKEditorComponent
            data={formData.des}
            onChange={handleEditorChange}
          />
        </div>
      </FormC>
    </LayoutBackend>
  );
};

export default TentangPage;
