import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

const LoginPage = () => {
  const [errors, setErrors] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const validateFormData = (formData: any) => {
    const errors: any = {};

    if (!formData.username) errors.username = "Username harus diisi!";
    if (!formData.password) errors.password = "Password harus diisi!";

    return errors;
  };
  useEffect(() => {
    document.title = "Halaman Login";
  });
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const formDataObj = Object.fromEntries(formData.entries());

    const validationErrors = validateFormData(formDataObj);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setIsLoading(false);
      return;
    }
    console.log(process.env.REACT_APP_API_URL);
    setIsLoading(true);
    try {
      await fetch(`${process.env.REACT_APP_API_URL}/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          username: formDataObj.username,
          password: formDataObj.password,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data.status);
          if (data.status === 200) {
            sessionStorage.setItem("token", data.token);
            sessionStorage.setItem("user", data.id_user);
            sessionStorage.setItem("username", data.username);
            window.location.href = "/dashboard";
          } else {
            setErrors({
              message: "Username atau password salah",
            });
            setIsLoading(false);
          }
        });
    } catch (error) {
      // console.error("Error submitting form data:", error);
      setErrors("Terjadi kesalahan. Silahkan coba lagi.");
      setIsLoading(false);
    }
  };
  return (
    <>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <title>AdminLTE 3 | Log in</title>
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700&display=fallback"
      />
      <link
        rel="stylesheet"
        href="/backend/plugins/fontawesome-free/css/all.min.css"
      />
      <link
        rel="stylesheet"
        href="/backend/plugins/icheck-bootstrap/icheck-bootstrap.min.css"
      />
      <link rel="stylesheet" href="/backend/dist/css/adminlte.min.css" />
      <div className="hold-transition login-page">
        <div className="login-box">
          <div className="card card-outline card-primary">
            <div className="card-header text-center">
              <a href="../../index2.html" className="h1">
                <b>HMJ</b>IT
              </a>
            </div>
            <div className="card-body">
              <p className="login-box-msg"> Login dengan akun anda</p>
              {errors.message && (
                <div className="alert border-0 bg-danger alert-dismissible fade show py-2">
                  <i className="icon fas fa-ban"></i>
                  {errors.message}
                </div>
              )}

              <form action="" onSubmit={handleSubmit}>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Username"
                    name="username"
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-user" />
                    </div>
                  </div>
                </div>
                <span className="text-danger">{errors.username}</span>
                <div className="input-group mb-3">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Password"
                    name="password"
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-lock" />
                    </div>
                  </div>
                </div>
                <span className="text-danger">{errors.password}</span>
                <div className="row">
                  {/* <div className="col-8">
                    <div className="icheck-primary">
                      <input type="checkbox" id="remember" />
                      <label htmlFor="remember">Remember Me</label>
                    </div>
                  </div> */}
                  <div className="col-12">
                    <button type="submit" className="btn btn-primary btn-block">
                      {isLoading ? "Loading..." : "Login"}
                    </button>
                  </div>
                  {/* /.col */}
                </div>
              </form>
            </div>
          </div>
        </div>
        <br />
        <strong>
          Copyright © 2024 <a href="https://adminlte.io">www.hmjit.co.id</a>.
        </strong>
      </div>
      <Helmet>
        <script src="/backend/plugins/jquery/jquery.min.js"></script>

        <script src="/backend/plugins/bootstrap/js/bootstrap.bundle.min.js"></script>

        <script src="/backend/dist/js/adminlte.min.js"></script>
      </Helmet>
    </>
  );
};

export default LoginPage;
