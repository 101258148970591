import axios from "axios";
// AnggotaController.ts
export interface Kegiatan {
  id_kegiatan: number;
  judul: string;
  gambar: string;
}

// Fungsi untuk mengambil data dari API
export const fetchMedia = async (): Promise<Kegiatan[]> => {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/kegiatan`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    }
  );
  if (!response.status) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }
  const data: Kegiatan[] = await response.data;
  return data;
};

// Fungsi untuk memfilter data berdasarkan pencarian
export const filterData = (data: Kegiatan[], search: string): Kegiatan[] => {
  return data.filter((kegiatan) =>
    kegiatan.judul.toLowerCase().includes(search.toLowerCase())
  );
};

// Fungsi untuk menghitung total halaman
export const getTotalPages = (
  filteredData: Kegiatan[],
  showCount: number
): number => {
  return Math.ceil(filteredData.length / showCount);
};

// Fungsi untuk mendapatkan data berdasarkan halaman saat ini
export const getPaginatedData = (
  filteredData: Kegiatan[],
  currentPage: number,
  showCount: number
): Kegiatan[] => {
  return filteredData.slice(
    (currentPage - 1) * showCount,
    currentPage * showCount
  );
};

// controller.ts

export const fetchFormData = async (id: string) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/kegiatan/${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    );
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching form data:", error);
    throw error;
  }
};

export const validateFormData = (formData: any) => {
  const errors: any = {};

  if (!formData.judul) errors.judul = "Judul harus diisi!";
  if (!formData.gambar) errors.gambar = "File harus diisi!";

  return errors;
};

export const submitFormData = async (formData: any, isEdit: boolean) => {
  const url = isEdit
    ? `${process.env.REACT_APP_API_URL}/update_kegiatan`
    : `${process.env.REACT_APP_API_URL}/kegiatan`;

  try {
    // Create a new FormData instance
    const body = new FormData();

    // Loop through the keys of formData
    for (const key in formData) {
      // Check if the value is a File object
      if (formData[key] instanceof File) {
        body.append(key, formData[key]);
      } else {
        body.append(key, String(formData[key])); // Ensure the value is a string
      }
    }

    const response = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        // Do not set Content-Type header when sending FormData
      },
      body: body,
    });

    console.log(response.body);

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    // Optionally, return the response or handle it as needed
    return await response.json();
  } catch (error) {
    console.error("Error submitting form data:", error);
    throw error;
  }
};
