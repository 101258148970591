// AnggotaController.ts
export interface User {
  id: number;
  nama: string;
  username: string;
  level: string;
  status: string;
}

// Fungsi untuk mengambil data dari API
export const fetchUser = async (): Promise<User[]> => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/user`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  });
  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }
  const data: User[] = await response.json();
  return data;
};
// Fungsi untuk memfilter data berdasarkan pencarian
export const filterData = (data: User[], search: string): User[] => {
  return data.filter((kritik) => {
    const nama = kritik.nama ? kritik.nama.toLowerCase() : "";
    const lowerCaseSearch = search.toLowerCase();

    return nama.includes(lowerCaseSearch);
  });
};

// Fungsi untuk menghitung total halaman
export const getTotalPages = (
  filteredData: User[],
  showCount: number
): number => {
  return Math.ceil(filteredData.length / showCount);
};

// Fungsi untuk mendapatkan data berdasarkan halaman saat ini
export const getPaginatedData = (
  filteredData: User[],
  currentPage: number,
  showCount: number
): User[] => {
  return filteredData.slice(
    (currentPage - 1) * showCount,
    currentPage * showCount
  );
};

// controller.ts

export const fetchFormData = async (id: string) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/user/${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    );
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching form data:", error);
    throw error;
  }
};

export const validateFormData = (formData: any) => {
  const errors: any = {};

  if (!formData.username) errors.username = "username harus diisi!";
  if (!formData.nama) errors.nama = "nama harus diisi!";
  if (!formData.password) errors.password = "subject harus diisi!";
  if (!formData.level) errors.level = "level harus diisi!";
  if (!formData.status) errors.status = "status harus diisi!";

  return errors;
};

export const submitFormData = async (formData: any, isEdit: boolean) => {
  const url = isEdit
    ? `${process.env.REACT_APP_API_URL}/update_user`
    : `${process.env.REACT_APP_API_URL}/user`;

  try {
    const body = formData instanceof FormData ? formData : new FormData();
    if (!(formData instanceof FormData)) {
      for (const key in formData) {
        body.append(key, formData[key]);
      }
    }

    const response = await fetch(url, {
      method: "POST",
      headers: {
        // "Content-Type" should not be set for FormData
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: body,
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
  } catch (error) {
    console.error("Error submitting form data:", error);
    throw error;
  }
};
