import React, { useEffect, useState } from "react";
import LayoutFrontend from "../component/frontend/MainLayout";
import {
  Artikel,
  fetchBlog,
  submitCari,
} from "../backend/blog/artikel/Controller";
import { useLocation } from "react-router-dom";
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};
const CariPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [artikel, setArtikel] = useState<Artikel[]>([]);
  const query = useQuery();
  const keyword = query.get("keyword") || "";

  useEffect(() => {
    document.title = "Halaman Cari";

    const fetchData = async () => {
      setIsLoading(true);
      try {
        const data = await submitCari({ keyword });
        setArtikel(data);
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (keyword) {
      fetchData();
    }
  }, [keyword]);
  const truncateText = (text: any, maxLength: any) => {
    if (!text) return "";
    return text.length > maxLength
      ? text.substring(0, maxLength) + "..."
      : text;
  };

  return (
    <LayoutFrontend>
      <>
        <div className="container-fluid bg-breadcrumb">
          <div className="container text-center py-5" style={{ maxWidth: 900 }}>
            <h4
              className="text-white display-4 mb-4 wow fadeInDown"
              data-wow-delay="0.1s"
            >
              Pencarian
            </h4>
            <ol
              className="breadcrumb d-flex justify-content-center mb-0 wow fadeInDown"
              data-wow-delay="0.3s"
            >
              <li className="breadcrumb-item">
                <a href="index.html">Beranda</a>
              </li>
              <li className="breadcrumb-item active text-primary">Artikel</li>
            </ol>
          </div>
        </div>
        <div className="container-fluid blog py-5">
          <div className="container py-5">
            <div className="row g-4 justify-content-center">
              {isLoading ? (
                <p>Loading...</p>
              ) : (
                <>
                  {artikel.length > 0 ? (
                    artikel.map((artikel) => (
                      <div
                        className="col-lg-6 col-xl-4 wow fadeInUp"
                        data-wow-delay="0.2s"
                        key={artikel.id_artikel}
                      >
                        <div className="blog-item">
                          <div className="blog-img">
                            <img
                              src={`${process.env.REACT_APP_API_IMAGE}/storage/${artikel.gambar}`}
                              className="img-fluid rounded-top w-100"
                              alt=""
                            />
                            <div className="blog-categiry py-2 px-4">
                              <span>{artikel.nama}</span>
                            </div>
                          </div>
                          <div className="blog-content p-4">
                            <div className="blog-comment d-flex justify-content-between mb-3">
                              <div className="small">
                                <span className="fa fa-user text-primary" />{" "}
                                {artikel.penulis}
                              </div>
                              <div className="small">
                                <span className="fa fa-calendar text-primary" />{" "}
                                {artikel.tanggal}
                              </div>
                              <div className="small">
                                <span className="fa fa-eye text-primary" /> 6
                              </div>
                            </div>
                            <a
                              href={`/blog/${artikel.slug}`}
                              className="h4 d-inline-block mb-3"
                            >
                              {truncateText(artikel.judul, 50)}
                            </a>
                            <p className="mb-3">{artikel.excerpt}....</p>
                            <a
                              href={`/blog/${artikel.slug}`}
                              className="btn p-0"
                            >
                              Baca <i className="fa fa-arrow-right" />
                            </a>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <>
                      <p className="text-center">Artikel tidak ditemukan</p>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </>
    </LayoutFrontend>
  );
};

export default CariPage;
