import { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { fetchFormData, validateFormData, submitFormData } from "./Controller";
import { useStateContext } from "../../../middleware";
import InputC from "../../../component/backend/Input";
import LayoutBackend from "../../../component/backend/Layout";
import FormC from "../../../component/backend/Form";

const AksiMasuk = () => {
  const { id } = useParams();
  const isEdit = Boolean(id);
  const navigate = useNavigate();
  const { token } = useStateContext();
  const [formData, setFormData] = useState({
    no_surat: "",
    tgl_surat: "",
    status:
      window.location.pathname === "/tambah_surat_masuk" ||
      window.location.pathname === `/surat_masuk/${id}`
        ? 1
        : 2,
    tgl_diterima: "",
    pengirim: "",
    ket: "",
    file: new File([""], ""),
    user_id: sessionStorage.getItem("user"),
  });
  const [errors, setErrors] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const titleSurat =
    window.location.pathname === `/surat_masuk/${id}` ||
    window.location.pathname === `/surat_masuk`
      ? "Surat Masuk"
      : "Surat Keluar";
  const link =
    window.location.pathname === "/tambah_surat_masuk" ||
    window.location.pathname === "/surat_masuk" ||
    window.location.pathname === `/surat_masuk/${id}`
      ? "/surat_masuk"
      : "/surat_keluar";
  useEffect(() => {
    document.title = isEdit ? `Edit ${titleSurat}` : `Tambah ${titleSurat}`;
    if (id) {
      fetchFormData(id)
        .then((data) => setFormData(data))
        .catch((error) => console.error("Error:", error));
    }
  }, [id, isEdit]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value, files } = e.target as HTMLInputElement;
    if (files && files.length > 0) {
      const file = files[0];
      if (file.size > 2 * 1024 * 1024) {
        // Memeriksa apakah ukuran file melebihi 2MB
        alert("Ukuran file tidak boleh melebihi 2MB!");
        return; // Menghentikan pemrosesan lebih lanjut
      }

      setFormData((prevData) => ({
        ...prevData,
        [name]: files[0],
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    const validationErrors = validateFormData(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setIsLoading(false);
      return;
    }
    console.log(formData);
    try {
      await submitFormData(formData, isEdit);
      navigate(`${link}`, {
        state: {
          message: isEdit
            ? "Data berhasil diperbarui!"
            : "Data berhasil ditambahkan!",
        },
      });
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };
  if (!token) {
    return <Navigate to="/login" />;
  }
  return (
    <LayoutBackend>
      <FormC
        aksi={handleSubmit}
        formTitle={isEdit ? `Edit ${titleSurat}` : `Tambah ${titleSurat}`}
        buttonTitle={isEdit ? `Update` : `Simpan`}
        buttonType={`submit`}
        hrefTitle={`Kembali`}
        href={link}
        loading={isLoading}
        encType={`multipart/form-data`}
      >
        <div className="col-6">
          <InputC
            label={`No Surat`}
            input_type={`text`}
            input_id={`no_surat`}
            name={"no_surat"}
            placeholder={`Masukkan nomor surat`}
            value={formData.no_surat}
            onChange={handleChange}
          />
          <span className="text-danger">{errors.no_surat}</span>
        </div>
        <div className="col-6">
          <InputC
            label={`Tanggal Surat`}
            input_type={`date`}
            input_id={`tgl_surat`}
            name={"tgl_surat"}
            value={formData.tgl_surat}
            onChange={handleChange}
          />
          <span className="text-danger">{errors.tgl_surat}</span>
        </div>
        <div className="col-6">
          <InputC
            label={`Pengirim`}
            input_type={`text`}
            input_id={`pengirim`}
            name={"pengirim"}
            placeholder={`Masukkan nama pengirim`}
            value={formData.pengirim}
            onChange={handleChange}
          />
          <span className="text-danger">{errors.pengirim}</span>
        </div>
        <div className="col-6">
          <InputC
            label={`Tanggal Diterima`}
            input_type={`date`}
            input_id={`tgl_diterima`}
            name={"tgl_diterima"}
            value={formData.tgl_diterima}
            onChange={handleChange}
          />
          <span className="text-danger">{errors.tgl_diterima}</span>
        </div>
        <div className="col-6">
          <InputC
            label={`Keterangan / Disposisi`}
            input_type={`text`}
            input_id={`ket`}
            name={"ket"}
            value={formData.ket}
            placeholder={`Masukkan keterangan / disposisi`}
            onChange={handleChange}
          />
          <span className="text-danger">{errors.ket}</span>
        </div>
        {window.location.pathname === "/tambah_surat_masuk" && (
          <>
            <div className="col-6">
              <InputC
                label={`Scan File`}
                input_type={`file`}
                input_id={`file`}
                name={"file"}
                onChange={handleChange}
              />
              <span className="text-danger">{errors.file}</span>
            </div>
          </>
        )}
        {window.location.pathname === `/surat_masuk/${id}` && (
          <>
            <div className="col-6">
              <InputC
                label={`Scan File`}
                input_type={`file`}
                input_id={`file`}
                name={"file"}
                onChange={handleChange}
              />
              <span className="text-danger">{errors.file}</span>
            </div>
          </>
        )}
      </FormC>
    </LayoutBackend>
  );
};

export default AksiMasuk;
