const InputC = (props: any) => {
  const {
    label,
    input_type,
    input_id,
    placeholder,
    name,
    onChange,
    value,
    maxLength,
  } = props;
  return (
    <>
      <div className="form-group">
        <label htmlFor={input_id}>{label}</label>
        <input
          type={input_type}
          className="form-control"
          id={input_id}
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          maxLength={maxLength}
        />
      </div>
    </>
  );
};
export default InputC;
