/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/img-redundant-alt */
import { useEffect, useState } from "react";
import LayoutFrontend from "../component/frontend/MainLayout";
import { About, fetchKontak } from "../backend/tentang/Controller";
import { submitFormData, validateFormData } from "../backend/kritik/Controller";
const KontakPage = () => {
  const [kontak, setKontak] = useState<About>();
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [message, setMessage] = useState("");

  const [formData, setFormData] = useState({
    nama: "",
    nim: "",
    subject: "",
    pesan: "",
  });
  const loadData = async () => {
    try {
      const data = await fetchKontak();
      setKontak(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target as HTMLInputElement;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    const validationErrors = validateFormData(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setIsLoading(false);
      return;
    }
    try {
      await submitFormData(formData, false);
      setMessage("pesan anda berhasil dikirim");
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadData();
    document.title = "Halaman Kontak";
  });
  console.log(kontak);
  return (
    <>
      <LayoutFrontend>
        <>
          <div className="container-fluid bg-breadcrumb">
            <div
              className="container text-center py-5"
              style={{ maxWidth: 900 }}
            >
              <h4
                className="text-white display-4 mb-4 wow fadeInDown"
                data-wow-delay="0.1s"
              >
                Kontak Kami
              </h4>
              <ol
                className="breadcrumb d-flex justify-content-center mb-0 wow fadeInDown"
                data-wow-delay="0.3s"
              >
                <li className="breadcrumb-item">
                  <a href="index.html">Beranda</a>
                </li>
                <li className="breadcrumb-item active text-primary">Kontak</li>
              </ol>
            </div>
          </div>
          <div className="container-fluid contact bg-light py-5">
            <div className="container py-5">
              <div
                className="text-center mx-auto pb-5 wow fadeInUp"
                data-wow-delay="0.2s"
                style={{ maxWidth: 800 }}
              >
                <h4 className="text-primary">Kontak Kami</h4>
                <h1 className="display-4 mb-4">Kritik dan Saran</h1>
              </div>
              <div className="row g-5">
                <div className="col-xl-6 wow fadeInLeft" data-wow-delay="0.2s">
                  <div className="contact-img d-flex justify-content-center">
                    <div className="contact-img-inner">
                      <img
                        src="/frontend/assets/img/contact-img.png"
                        className="img-fluid w-100"
                        alt="Image"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 wow fadeInRight" data-wow-delay="0.4s">
                  <div>
                    <h4 className="text-primary">Kirim Pesan Anda</h4>
                    {message && (
                      <div className="alert alert-success" role="alert">
                        {message}
                      </div>
                    )}
                    <form onSubmit={handleSubmit}>
                      <div className="row g-3">
                        <div className="col-lg-12 col-xl-6">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control border-0"
                              id="nama"
                              name="nama"
                              placeholder="Nama Lengkap"
                              onChange={handleChange}
                            />
                            {errors.nama && (
                              <p className="text-danger">{errors.nama}</p>
                            )}
                            <label htmlFor="nama">Nama Lengkap</label>
                          </div>
                        </div>
                        <div className="col-lg-12 col-xl-6">
                          <div className="form-floating">
                            <input
                              type="number"
                              className="form-control border-0"
                              id="nim"
                              name="nim"
                              placeholder="NIM"
                              onChange={handleChange}
                            />
                            {errors.nim && (
                              <p className="text-danger">{errors.nim}</p>
                            )}
                            <label htmlFor="nim">NIM</label>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control border-0"
                              id="subject"
                              name="subject"
                              placeholder="Subjek"
                              onChange={handleChange}
                            />
                            {errors.subject && (
                              <p className="text-danger">{errors.subject}</p>
                            )}
                            <label htmlFor="subject">Subjek</label>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-floating">
                            <textarea
                              className="form-control border-0"
                              placeholder="Leave a message here"
                              id="message"
                              name="pesan"
                              style={{ height: 120 }}
                              onChange={handleChange}
                            />
                            {errors.nama && (
                              <p className="text-danger">{errors.pesan}</p>
                            )}
                            <label htmlFor="message">Pesan</label>
                          </div>
                        </div>
                        <div className="col-12">
                          <button
                            type="submit"
                            className="btn btn-primary w-100 py-3"
                          >
                            {" "}
                            {isLoading ? "Loading..." : "Kirim Pesan"}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="col-12">
                  <div>
                    <div className="row g-4">
                      <div
                        className="col-md-6 col-lg-3 wow fadeInUp"
                        data-wow-delay="0.2s"
                      >
                        <div className="contact-add-item">
                          <div className="contact-icon text-primary mb-4">
                            <i className="fas fa-map-marker-alt fa-2x" />
                          </div>
                          <div>
                            <h4>Alamat</h4>
                            <p className="mb-0"> {kontak?.alamat}</p>
                          </div>
                        </div>
                      </div>
                      <div
                        className="col-md-6 col-lg-3 wow fadeInUp"
                        data-wow-delay="0.4s"
                      >
                        <div className="contact-add-item">
                          <div className="contact-icon text-primary mb-4">
                            <i className="fas fa-envelope fa-2x" />
                          </div>
                          <div>
                            <h4>Email</h4>
                            <p className="mb-0">{kontak?.email}</p>
                          </div>
                        </div>
                      </div>
                      <div
                        className="col-md-6 col-lg-3 wow fadeInUp"
                        data-wow-delay="0.6s"
                      >
                        <div className="contact-add-item">
                          <div className="contact-icon text-primary mb-4">
                            <i className="fa fa-phone-alt fa-2x" />
                          </div>
                          <div>
                            <h4>Telephone</h4>
                            <p className="mb-0"> {kontak?.nomor}</p>
                          </div>
                        </div>
                      </div>
                      <div
                        className="col-md-6 col-lg-3 wow fadeInUp"
                        data-wow-delay="0.8s"
                      >
                        <div className="contact-add-item">
                          <div className="contact-icon text-primary mb-4">
                            <i className="fab fa-firefox-browser fa-2x" />
                          </div>
                          <div>
                            <h4>Jam Opersional</h4>
                            <p className="mb-0">{kontak?.jam}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 wow fadeInUp" data-wow-delay="0.2s">
                  <div className="rounded">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3949.8485295044748!2d113.21625897529496!3d-8.116900781226503!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dd65d690a862791%3A0xd74a8f892e1b8d4e!2sInstitut%20Teknologi%20dan%20Bisnis%20Widya%20Gama!5e0!3m2!1sid!2sid!4v1722602931194!5m2!1sid!2sid"
                      height={400}
                      className="w-100"
                      style={{ border: 0 }}
                      loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </LayoutFrontend>
    </>
  );
};

export default KontakPage;
