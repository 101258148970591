import LinkC from "./Link";

const TableC = (props: any) => {
  const { children, title, href, titlehref, titlehref2, href2 } = props;
  return (
    <div className="content">
      <div className="row">
        {href && (
          <>
            <LinkC href={href} color={"primary"}>
              {titlehref}
            </LinkC>
            {window.location.pathname === "/anggota" && (
              <LinkC href={href2} color={"success"}>
                {titlehref2}
              </LinkC>
            )}
            {window.location.pathname === "/artikel" && (
              <LinkC href={href2} color={"success"}>
                {titlehref2}
              </LinkC>
            )}
          </>
        )}
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">{title}</h3>
            </div>
            <div className="card-body">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TableC;
