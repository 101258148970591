// AnggotaController.ts
export interface SuratMasuk {
  id_surat: number;
  no_surat: string;
  tgl_surat: string;
  tgl_diterima: string;
  pengirim: string;
  penerima: string;
  ket: string;
  status: number;
  file: string;
}

// Fungsi untuk mengambil data dari API
export const fetchMasuk = async (): Promise<SuratMasuk[]> => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/surat_masuk`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  });
  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }
  const data: SuratMasuk[] = await response.json();
  return data;
};

export const fetchKeluar = async (): Promise<SuratMasuk[]> => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/surat_keluar`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    }
  );
  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }
  const data: SuratMasuk[] = await response.json();
  return data;
};

// Fungsi untuk memfilter data berdasarkan pencarian
export const filterData = (
  data: SuratMasuk[],
  search: string
): SuratMasuk[] => {
  return data.filter((artikel) =>
    artikel.no_surat.toLowerCase().includes(search.toLowerCase())
  );
};

// Fungsi untuk menghitung total halaman
export const getTotalPages = (
  filteredData: SuratMasuk[],
  showCount: number
): number => {
  return Math.ceil(filteredData.length / showCount);
};

// Fungsi untuk mendapatkan data berdasarkan halaman saat ini
export const getPaginatedData = (
  filteredData: SuratMasuk[],
  currentPage: number,
  showCount: number
): SuratMasuk[] => {
  return filteredData.slice(
    (currentPage - 1) * showCount,
    currentPage * showCount
  );
};

// controller.ts

export const fetchFormData = async (id: string) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/surat/${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    );
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching form data:", error);
    throw error;
  }
};

export const validateFormData = (formData: any) => {
  const errors: any = {};

  if (!formData.no_surat) errors.no_surat = "No Surat harus diisi!";
  if (!formData.tgl_surat) errors.tgl_surat = "Tanggal Surat harus diisi!";
  if (!formData.pengirim) errors.pengirim = "Pengirim harus diisi!";
  if (!formData.tgl_diterima)
    errors.tgl_diterima = "Tanggal surat diterima harus diisi!";
  if (!formData.ket) errors.ket = "Keterangan harus diisi!";
  if (!formData.file) errors.file = "Scan file harus diisi!";

  return errors;
};

export const submitFormData = async (formData: any, isEdit: boolean) => {
  const url = isEdit
    ? `${process.env.REACT_APP_API_URL}/update_surat`
    : `${process.env.REACT_APP_API_URL}/surat`;

  try {
    // Check if formData is already FormData, else convert it
    const body = formData instanceof FormData ? formData : new FormData();
    if (!(formData instanceof FormData)) {
      for (const key in formData) {
        body.append(key, formData[key]);
      }
    }

    const response = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: body,
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
  } catch (error) {
    console.error("Error submitting form data:", error);
    throw error;
  }
};
