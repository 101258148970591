import { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import FormC from "../../../component/backend/Form";
import InputC from "../../../component/backend/Input";
import LayoutBackend from "../../../component/backend/Layout";
import { fetchFormData, validateFormData, submitFormData } from "./Controller";
import { useStateContext } from "../../../middleware";
import { fetchKategori, Kategori } from "../kategori/Controller";
import CKEditorComponent from "../../../component/backend/Ckeditor";

const AksiArtikel = () => {
  const { id } = useParams();
  const isEdit = Boolean(id);
  const navigate = useNavigate();
  const { token } = useStateContext();
  const [formData, setFormData] = useState({
    judul: "",
    kategori_id: "",
    excerpt: "",
    artikel: "",
    status: "",
    user_id: sessionStorage.getItem("user"),
    gambar: new File([], ""),
  });
  const [errors, setErrors] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [kategori, setKategori] = useState<Kategori[]>([]);
  const [filteredKategori, setFilteredKategori] = useState<Kategori[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  useEffect(() => {
    document.title = isEdit ? "Edit Anggota" : "Tambah Anggota";
    if (id) {
      fetchFormData(id)
        .then((data) => setFormData(data))
        .catch((error) => console.error("Error:", error));
    }
  }, [id, isEdit]);

  useEffect(() => {
    const loadData = async () => {
      try {
        const data = await fetchKategori();
        setKategori(data);
        setFilteredKategori(data); // Set initial filtered data
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    loadData();
  }, []);

  useEffect(() => {
    // Filter anggota based on searchTerm
    setFilteredKategori(
      kategori.filter((item) =>
        item.nama.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, kategori]);
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value, files } = e.target as HTMLInputElement;
    if (files && files.length > 0) {
      const file = files[0];
      if (file.size > 2 * 1024 * 1024) {
        // Memeriksa apakah ukuran file melebihi 2MB
        alert("Ukuran file tidak boleh melebihi 2MB!");
        return; // Menghentikan pemrosesan lebih lanjut
      }
      // Membuat URL sementara untuk prapratampilan gambar
      const imageUrl = URL.createObjectURL(file);
      setImagePreview(imageUrl);
      setFormData((prevData) => ({
        ...prevData,
        [name]: files[0],
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  const handleEditorChange = (data: string) => {
    setFormData((prevData) => ({
      ...prevData,
      artikel: data,
    }));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    const validationErrors = validateFormData(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setIsLoading(false);
      return;
    }
    console.log(formData);
    try {
      await submitFormData(formData, isEdit);
      navigate("/artikel", {
        state: {
          message: isEdit
            ? "Data berhasil diperbarui!"
            : "Data berhasil ditambahkan!",
        },
      });
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };
  if (!token) {
    return <Navigate to="/login" />;
  }
  return (
    <LayoutBackend>
      <FormC
        aksi={handleSubmit}
        formTitle={isEdit ? `Edit Artikel` : `Tambah Artikel`}
        buttonTitle={isEdit ? `Update` : `Simpan`}
        buttonType={`submit`}
        hrefTitle={`Kembali`}
        href={`/artikel`}
        loading={isLoading}
      >
        {/* <InputC input_type={`hidden`} name={"nim"} value={formData.id_ang} /> */}
        <div className="col-12">
          <InputC
            label={`Judul Artikel`}
            input_type={`text`}
            input_id={`judul`}
            name={"judul"}
            placeholder={`Masukkan Judul`}
            value={formData.judul}
            onChange={handleChange}
          />
          <span className="text-danger">{errors.judul}</span>
        </div>
        <div className="col-12">
          <label htmlFor="nama">Kategori </label>
          <div className="row">
            <div className="col-md-6">
              <input
                type="text"
                className="form-control mb-2"
                placeholder="Cari kategori..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <div className="col-md-6">
              <select
                name="kategori_id"
                id="kategori_id"
                className="form-control"
                value={formData.kategori_id}
                onChange={handleChange}
              >
                <option value="">-pilih-</option>
                {filteredKategori.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.nama}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <span className="text-danger">{errors.kategori_id}</span>
        </div>
        <div className="col-12">
          <InputC
            label={`Kutipan`}
            input_type={`text`}
            input_id={`excerpt`}
            name={"excerpt"}
            placeholder={`Kutipan`}
            value={formData.excerpt}
            onChange={handleChange}
            maxLength={122}
          />
          <span className="text-danger">{errors.excerpt}</span>
        </div>
        <div className="col-6">
          <InputC
            label={`Upload Gambar`}
            input_type={`file`}
            input_id={`gambar`}
            name={"gambar"}
            onChange={handleChange}
          />
          {isEdit ? (
            <img
              src={`${process.env.REACT_APP_API_IMAGE}/storage/${formData.gambar}`}
              className="img-fluid"
              width={100}
              alt=""
            />
          ) : (
            imagePreview && (
              <img
                src={imagePreview!}
                className="img-fluid"
                width={100}
                alt="Preview"
              />
            )
          )}
        </div>
        <div className="col-6">
          <div className="form-group">
            <label htmlFor="status">Status</label>
            <select
              name="status"
              id="status"
              className="form-control"
              value={formData.status}
              onChange={handleChange}
            >
              <option value="">-pilih-</option>
              <option value="1">Publish</option>
              <option value="2">Draf</option>
            </select>
            <span className="text-danger">{errors.status}</span>
          </div>
        </div>
        <div className="col-12">
          <label htmlFor="artikel">Artikel</label>
          <CKEditorComponent
            data={formData.artikel}
            onChange={handleEditorChange}
          />
          <span className="text-danger">{errors.artikel}</span>
        </div>
      </FormC>
    </LayoutBackend>
  );
};

export default AksiArtikel;
