/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import LayoutBackend from "../component/backend/Layout";
import { useStateContext } from "../middleware";
import { Navigate } from "react-router-dom";
import { fetchUser, User } from "./user/Controller";
import { Artikel, fetchArtikel } from "./blog/artikel/Controller";
import { Archive, fetchArchive } from "./file/Controller";
import { fetchMedia, Kegiatan } from "./media/Controller";
import LineChart from "../component/backend/Chart";
import BarChart from "../component/backend/Chart";
import TableC from "../component/backend/Table";

interface Trending {
  judul: string;
  artikel_id: number;
  total: string;
}
const DashboardPage = () => {
  const [user, setUser] = useState<User[]>([]);
  const [artikel, setArtikel] = useState<Artikel[]>([]);
  const [trending, setTrending] = useState<Trending[]>([]);
  const [archive, setArchive] = useState<Archive[]>([]);
  const [media, setMedia] = useState<Kegiatan[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { token } = useStateContext();

  useEffect(() => {
    document.title = "Halaman Dashboard";
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const [userResult, artikelResult, archiveResult, mediaResult] =
          await Promise.all([
            fetchUser(),
            fetchArtikel(),
            fetchArchive(),
            fetchMedia(),
          ]);

        setUser(userResult);
        setArtikel(artikelResult);
        setArchive(archiveResult);
        setMedia(mediaResult);
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/trending`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const result: Trending[] = await response.json();
        setTrending(result);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  if (!token) {
    return <Navigate to="/login" />;
  }

  return (
    <LayoutBackend>
      <section className="content">
        <div className="container-fluid">
          {/* Small boxes (Stat box) */}
          <div className="row">
            <div className="col-lg-3 col-6">
              {/* small box */}
              <div className="small-box bg-info">
                <div className="inner">
                  <h3>{user.length}</h3>
                  <p>User</p>
                </div>
                <div className="icon">
                  <i className="ion ion-person-add" />
                </div>
                <a href="#" className="small-box-footer">
                  More info <i className="fas fa-arrow-circle-right" />
                </a>
              </div>
            </div>
            {/* ./col */}
            <div className="col-lg-3 col-6">
              {/* small box */}
              <div className="small-box bg-success">
                <div className="inner">
                  <h3>{artikel.length}</h3>
                  <p>Artikel</p>
                </div>
                <div className="icon">
                  <i className="ion ion-stats-bars" />
                </div>
                <a href="#" className="small-box-footer">
                  More info <i className="fas fa-arrow-circle-right" />
                </a>
              </div>
            </div>
            {/* ./col */}
            <div className="col-lg-3 col-6">
              {/* small box */}
              <div className="small-box bg-warning">
                <div className="inner">
                  <h3>{archive.length}</h3>
                  <p>Archive</p>
                </div>
                <div className="icon">
                  <i className="ion ion-archive" />
                </div>
                <a href="#" className="small-box-footer">
                  More info <i className="fas fa-arrow-circle-right" />
                </a>
              </div>
            </div>
            {/* ./col */}
            <div className="col-lg-3 col-6">
              {/* small box */}
              <div className="small-box bg-danger">
                <div className="inner">
                  <h3>{media.length}</h3>
                  <p>Dokumentasi</p>
                </div>
                <div className="icon">
                  <i className="ion ion-image" />
                </div>
                <a href="#" className="small-box-footer">
                  More info <i className="fas fa-arrow-circle-right" />
                </a>
              </div>
            </div>
            {/* ./col */}
          </div>
        </div>
      </section>
      <div className="row">
        <section className="col-lg-7 connectedSortable ui-sortable">
          <div className="card">
            <div
              className="card-header ui-sortable-handle"
              style={{ cursor: "move" }}
            >
              <h3 className="card-title">Statistik Artikel</h3>
            </div>
            {/* /.card-header */}
            <div className="card-body">
              <BarChart />
            </div>
          </div>
        </section>
        <section className="col-lg-5 connectedSortable ui-sortable">
          <div className="card">
            <div
              className="card-header ui-sortable-handle"
              style={{ cursor: "move" }}
            >
              <h3 className="card-title">Trending Bulan ini</h3>
            </div>
            {/* /.card-header */}
            <div className="card-body">
              <table
                id="example"
                className="table table-striped"
                style={{ width: "100%" }}
              >
                <thead>
                  <tr>
                    <th>Judul</th>
                    <th>View</th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <>
                      {" "}
                      <center>Loading ...</center>
                    </>
                  ) : (
                    <>
                      {trending.map((item) => (
                        <tr key={item.artikel_id}>
                          <td>{item.judul}</td>
                          <td>{item.total}</td>
                        </tr>
                      ))}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </section>
      </div>
    </LayoutBackend>
  );
};

export default DashboardPage;
