import { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import FormC from "../../../component/backend/Form";
import InputC from "../../../component/backend/Input";
import LayoutBackend from "../../../component/backend/Layout";
import {
  fetchFormData,
  validateFormData,
  submitFormData,
  fetchAnggota,
  Anggota,
} from "./Controller";
import { useStateContext } from "../../../middleware";

const AksiPengurus = () => {
  const { id } = useParams();
  const isEdit = Boolean(id);
  const navigate = useNavigate();
  const { token } = useStateContext();
  const [anggota, setAnggota] = useState<Anggota[]>([]);
  const [filteredAnggota, setFilteredAnggota] = useState<Anggota[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [formData, setFormData] = useState({
    anggota_id: "",
    sub: "",
    about: "",
    tiktok: "",
    instagram: "",
    twitter: "",
    facebook: "",
  });
  const [errors, setErrors] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    document.title = isEdit ? "Edit Anggota" : "Tambah Anggota";
    if (id) {
      fetchFormData(id)
        .then((data) => setFormData(data))
        .catch((error) => console.error("Error:", error));
    }
  }, [id, isEdit]);

  useEffect(() => {
    const loadData = async () => {
      try {
        const data = await fetchAnggota();
        setAnggota(data);
        setFilteredAnggota(data); // Set initial filtered data
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    loadData();
  }, []);

  useEffect(() => {
    // Filter anggota based on searchTerm
    setFilteredAnggota(
      anggota.filter((item) =>
        item.nama.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, anggota]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value, files } = e.target as HTMLInputElement;
    if (files && files.length > 0) {
      setFormData((prevData) => ({
        ...prevData,
        [name]: files[0],
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    const validationErrors = validateFormData(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setIsLoading(false);
      return;
    }

    try {
      await submitFormData(formData, isEdit);
      navigate("/pengurus", {
        state: {
          message: isEdit
            ? "Data berhasil diperbarui!"
            : "Data berhasil ditambahkan!",
        },
      });
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  if (!token) {
    return <Navigate to="/login" />;
  }

  return (
    <LayoutBackend>
      <FormC
        aksi={handleSubmit}
        formTitle={isEdit ? `Edit Pengurus` : `Tambah Pengurus`}
        buttonTitle={isEdit ? `Update` : `Simpan`}
        buttonType={`submit`}
        hrefTitle={`Kembali`}
        href={`/pengurus`}
        loading={isLoading}
        encType={`multipart/form-data`}
      >
        <div className="col-6">
          <label htmlFor="nama">Nama </label>
          <div className="row">
            <div className="col-md-6">
              <input
                type="text"
                className="form-control mb-2"
                placeholder="Cari anggota..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <div className="col-md-6">
              <select
                name="anggota_id"
                id="anggota_id"
                className="form-control"
                value={formData.anggota_id}
                onChange={handleChange}
              >
                <option value="">-pilih-</option>
                {filteredAnggota.map((item) => (
                  <option key={item.id_ang} value={item.id_ang}>
                    {item.nama}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <span className="text-danger">{errors.anggota_id}</span>
        </div>
        <div className="col-6">
          <InputC
            label={`Jabatan`}
            input_type={`text`}
            input_id={`sub`}
            name={"sub"}
            placeholder={`Jabatan`}
            value={formData.sub}
            onChange={handleChange}
          />
          <span className="text-danger">{errors.sub}</span>
        </div>
        <div className="col-12">
          <InputC
            label={`Tentang`}
            input_type={`text`}
            input_id={`about`}
            name={"about"}
            value={formData.about}
            onChange={handleChange}
            placeholder={`Tentang Anda`}
          />
          <span className="text-danger">{errors.about}</span>
        </div>

        <div className="col-6">
          <InputC
            label={`Tiktok`}
            input_type={`text`}
            input_id={`tiktok`}
            name={"tiktok"}
            placeholder={`Nomor Hp`}
            value={formData.tiktok}
            onChange={handleChange}
          />
          <span className="text-danger">{errors.tiktok}</span>
        </div>
        <div className="col-6">
          <InputC
            label={`Instagram`}
            input_type={`text`}
            input_id={`instagram`}
            name={"instagram"}
            onChange={handleChange}
            placeholder={`Link Halaman Instagram`}
            value={formData.instagram}
          />
          <span className="text-danger">{errors.instagram}</span>
        </div>
        <div className="col-6">
          <InputC
            label={`Twitter`}
            input_type={`text`}
            input_id={`twitter`}
            name={"twitter"}
            onChange={handleChange}
            placeholder={`Link Halaman Twitter`}
            value={formData.twitter}
          />
          <span className="text-danger">{errors.twitter}</span>
        </div>
        <div className="col-6">
          <InputC
            label={`Facebook`}
            input_type={`text`}
            input_id={`facebook`}
            name={"facebook"}
            onChange={handleChange}
            placeholder={`Link Halaman Facebook`}
            value={formData.facebook}
          />
          <span className="text-danger">{errors.facebook}</span>
        </div>
      </FormC>
    </LayoutBackend>
  );
};

export default AksiPengurus;
