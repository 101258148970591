const ButtonC = (props: any) => {
  const { children, type } = props;
  return (
    <>
      <button type={type} className="btn btn-primary">
        {children}
      </button>
    </>
  );
};
export default ButtonC;
