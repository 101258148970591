// AnggotaController.ts
export interface Archive {
  id_archive: number;
  nama: string;
  file: string;
  status: string;
}

// Fungsi untuk mengambil data dari API
export const fetchArchive = async (): Promise<Archive[]> => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/file_arc`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  });
  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }
  const data: Archive[] = await response.json();
  return data;
};
export const fetchPublic = async (): Promise<Archive[]> => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/public_file`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
    body: JSON.stringify({ token: process.env.REACT_APP_TOKEN }),
  });
  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }
  const data: Archive[] = await response.json();
  return data;
};
// Fungsi untuk memfilter data berdasarkan pencarian
export const filterData = (data: Archive[], search: string): Archive[] => {
  return data.filter((artikel) =>
    artikel.nama.toLowerCase().includes(search.toLowerCase())
  );
};

// Fungsi untuk menghitung total halaman
export const getTotalPages = (
  filteredData: Archive[],
  showCount: number
): number => {
  return Math.ceil(filteredData.length / showCount);
};

// Fungsi untuk mendapatkan data berdasarkan halaman saat ini
export const getPaginatedData = (
  filteredData: Archive[],
  currentPage: number,
  showCount: number
): Archive[] => {
  return filteredData.slice(
    (currentPage - 1) * showCount,
    currentPage * showCount
  );
};

// controller.ts

export const fetchFormData = async (id: string) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/file_arc/${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    );
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching form data:", error);
    throw error;
  }
};

export const validateFormData = (formData: any) => {
  const errors: any = {};

  if (!formData.nama) errors.nama = "Judul harus diisi!";
  if (!formData.file) errors.file = "File harus diisi!";
  if (!formData.status) errors.status = "Status harus diisi!";

  return errors;
};

export const submitFormData = async (formData: any, isEdit: boolean) => {
  const url = isEdit
    ? `${process.env.REACT_APP_API_URL}/update_archive`
    : `${process.env.REACT_APP_API_URL}/file_arc`;

  try {
    const body = formData instanceof FormData ? formData : new FormData();
    if (!(formData instanceof FormData)) {
      for (const key in formData) {
        body.append(key, formData[key]);
      }
    }
    console.log(formData);
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: body,
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    return await response.json(); // Assuming the API returns JSON data
  } catch (error) {
    console.error("Error submitting form data:", error);
    throw error;
  }
};
