import { Link, useLocation } from "react-router-dom";

const LinkC = (props: any) => {
  const { children, href, icon, onClick } = props;
  const location = useLocation();
  const currentPath = location.pathname;
  return (
    <li className="nav-item">
      <Link
        onClick={onClick}
        to={href}
        className={`nav-link ${currentPath === href ? "active" : ""}`}
      >
        <i className={`nav-icon ${icon}`} />
        <p>{children}</p>
      </Link>
    </li>
  );
};
export default LinkC;
