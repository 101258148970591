/* eslint-disable react/jsx-no-target-blank */
import { useEffect, useState } from "react";
import LayoutFrontend from "../component/frontend/MainLayout";
import {
  Archive,
  fetchPublic,
  filterData,
  getPaginatedData,
  getTotalPages,
} from "../backend/file/Controller";

const ArchivePublicPage = () => {
  const [archive, setArchive] = useState<Archive[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState<string>("");
  const [showCount, setShowCount] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const handleShowCountChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setShowCount(Number(e.target.value));
    setCurrentPage(1);
  };

  // Mengatur halaman berikutnya
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Mengatur halaman sebelumnya
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const filteredData = filterData(archive, search);

  // Menghitung total halaman
  const totalPages = getTotalPages(filteredData, showCount);

  // Mendapatkan data yang akan ditampilkan berdasarkan halaman saat ini
  const paginatedData = getPaginatedData(filteredData, currentPage, showCount);
  useEffect(() => {
    document.title = "Halaman Archive";
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const [detailResult] = await Promise.all([fetchPublic()]);
        setArchive(detailResult);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <LayoutFrontend>
        <div className="container-fluid bg-light p-3">
          <div
            className="text-center mx-auto pb-5 wow fadeInUp"
            data-wow-delay="0.2s"
            style={{ maxWidth: 900 }}
          >
            <h1 className="display-4 mb-4">Archive HMJ Informatika</h1>
            <p className="mb-0">
              Data Archive HMJ Informatika anda bisa melihat dan mendoanload
              data di table archive dibawah ini
            </p>
          </div>
          <div className="row g-4 mb-2">
            <div className="col-sm-12">
              <div className="d-flex justify-content-between align-items-center">
                <div className="dataTables_length">
                  <label>
                    Show{" "}
                    <select
                      name="example_length"
                      aria-controls="example"
                      className="form-select form-select-sm"
                      onChange={handleShowCountChange}
                    >
                      <option value={10}>10</option>
                      <option value={25}>25</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                    </select>
                  </label>
                </div>
                <div className="dataTables_filter">
                  <label className="w-100">
                    Search:
                    <input
                      type="search"
                      className="form-control form-control-sm"
                      placeholder=""
                      aria-controls="example"
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <div className="table-responsive">
                <table
                  id="example"
                  className="table table-striped"
                  style={{ width: "100%" }}
                >
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Nama File</th>
                      <th>Aksi</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading ? (
                      <tr>
                        <td colSpan={3} className="text-center">
                          <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </td>
                      </tr>
                    ) : (
                      <>
                        {paginatedData.length > 0 ? (
                          paginatedData.map((archive, index) => (
                            <tr key={archive.id_archive}>
                              <td>{index + 1}</td>
                              <td>{archive.nama}</td>
                              <td>
                                <a
                                  href={`${process.env.REACT_APP_API_IMAGE}/storage/${archive.file}`}
                                  className="btn btn-primary"
                                  target="_blank"
                                >
                                  <i className="fa fa-download"></i>
                                </a>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={3} className="text-center">
                              Data Tidak ditemukan
                            </td>
                          </tr>
                        )}
                      </>
                    )}
                  </tbody>
                </table>
                <div
                  id="example_paginate"
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <ul className="pagination">
                    <li
                      className={`paginate_button page-item previous ${
                        currentPage === 1 ? "disabled" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={handlePrevPage}
                        disabled={currentPage === 1}
                      >
                        Prev
                      </button>
                    </li>
                    {[...Array(totalPages)].map((_, pageIndex) => (
                      <li
                        key={pageIndex}
                        className={`paginate_button page-item ${
                          currentPage === pageIndex + 1 ? "active" : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => setCurrentPage(pageIndex + 1)}
                        >
                          {pageIndex + 1}
                        </button>
                      </li>
                    ))}
                    <li
                      className={`paginate_button page-item next ${
                        currentPage === totalPages ? "disabled" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages}
                      >
                        Next
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutFrontend>
    </>
  );
};

export default ArchivePublicPage;
