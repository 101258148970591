import React from "react";
import { Helmet } from "react-helmet";
import HeaderC from "./Header";
import BannerC from "./Banner";
import FooterC from "./FooterC";
import { useEffect } from "react";

const LayoutFrontend = (props: any) => {
  const { children } = props;

  useEffect(() => {
    document.title = "Selamat Datang di Web HMJIT";
  });

  return (
    <>
      <meta charSet="utf-8" />
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Inter:slnt,wght@-10..0,100..900&display=swap"
          rel="stylesheet"
        />
        <link
          rel="stylesheet"
          href="https://use.fontawesome.com/releases/v5.15.4/css/all.css"
        />
        <link
          href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.4.1/font/bootstrap-icons.css"
          rel="stylesheet"
        />
        <link
          rel="stylesheet"
          href="/frontend/assets/lib/animate/animate.min.css"
        />
        <link
          href="/frontend/assets/lib/lightbox/css/lightbox.min.css"
          rel="stylesheet"
        />
        <link
          href="/frontend/assets/lib/owlcarousel/assets/owl.carousel.min.css"
          rel="stylesheet"
        />
        <link href="/frontend/assets/css/bootstrap.min.css" rel="stylesheet" />
        <link href="/frontend/assets/css/style.css" rel="stylesheet" />
        <link href="/frontend/lightbox.css" rel="stylesheet" />
        <link
          href="https://cdn.datatables.net/2.1.0/css/dataTables.bootstrap5.css"
          rel="stylesheet"
        />
      </Helmet>
      <HeaderC />
      {window.location.pathname === "/" && <BannerC />}
      {children}
      <FooterC />
      <Helmet>
        <script src="https://kedawung.sidem.id/frontend/assets/js/video.js"></script>
      </Helmet>
    </>
  );
};
export default LayoutFrontend;
