const BannerC = () => {
  return (
    <>
      <div className="video-container">
        <video autoPlay muted loop id="bg-video">
          <source src="https://sipeta.my.id/hmjitfix.mp4" type="video/mp4" />
        </video>
        <div className="video-overlay header-text">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="caption">
                  <h2>Selamat Datang di HMJIT</h2>
                  <h6 style={{ color: "white" }}>
                    Sistem Informasi Himpunan Mahasiswa Jurusan Informatika
                  </h6>
                  <br />
                  <p>
                    HMJ-IT adalah sebuah organisasi kemahasiswaan yang mewadahi
                    minat dan bakat mahasiswa Informatika, memfasilitasi
                    pengembangan diri, dan membangun jiwa kepemimpinan serta
                    kerjasama
                  </p>
                  {/* <div className="main-button-red">
                    <a href="/halaman/sistem-informasi-desa-maju">
                      Selengkapnya
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BannerC;
