/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { About, fetchKontak } from "../../backend/tentang/Controller";
import { fetchMedia, Kegiatan } from "../../backend/media/Controller";

const FooterC = () => {
  const [kontak, setKontak] = useState<About>();
  const [media, setMedia] = useState<Kegiatan[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const [kontakResult, mediaResult, ,] = await Promise.all([
          fetchKontak(),
          fetchMedia(),
        ]);

        setKontak(kontakResult);
        setMedia(mediaResult);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);
  const truncateText = (text: any, maxLength: any) => {
    if (!text) return "";
    return text.length > maxLength
      ? text.substring(0, maxLength) + "..."
      : text;
  };
  return (
    <>
      <div
        className="container-fluid footer py-5 wow fadeIn"
        data-wow-delay="0.2s"
      >
        <div className="container py-1">
          <div className="row g-5">
            <div className="col-xl-9">
              <div className="row g-4">
                <div className="col-md-6 col-lg-6 col-xl-5">
                  <div className="footer-item">
                    <a href="index.html" className="p-0">
                      <h3 className="text-white">
                        <i className="fab fa-slack me-3" /> HMJ INFORMATIKA
                      </h3>
                      {/* <img src="/frontend/assets/img/logo.png" alt="Logo"> */}
                    </a>
                    <p className="text-white mb-4">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: truncateText(kontak?.des, 100) ?? "",
                        }}
                      ></div>
                    </p>
                    <div className="footer-btn d-flex">
                      <Link
                        to={`${kontak?.fby}`}
                        className="btn btn-md-square rounded-circle me-3"
                        target="blank"
                      >
                        <i className="fab fa-facebook-f" />
                      </Link>
                      <Link
                        className="btn btn-md-square rounded-circle me-3"
                        to={`${kontak?.twit}`}
                        target="blank"
                      >
                        <i className="fab fa-twitter" />
                      </Link>
                      <Link
                        className="btn btn-md-square rounded-circle me-3"
                        to={`${kontak?.ig}`}
                        target="blank"
                      >
                        <i className="fab fa-instagram" />
                      </Link>
                      <Link
                        className="btn btn-md-square rounded-circle me-0"
                        to={`${kontak?.tiktok}`}
                        target="blank"
                      >
                        <i className="fab fa-tiktok" />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-6 col-xl-3">
                  <div className="footer-item">
                    <h4 className="text-white mb-4">Navigasi</h4>
                    <Link to={"/kegiatan"}>
                      <i className="fas fa-angle-right me-2" /> Kegiatan
                    </Link>
                    <Link to={"/archive"}>
                      <i className="fas fa-angle-right me-2" /> Archive
                    </Link>
                    <Link to={"/dokumentasi"}>
                      <i className="fas fa-angle-right me-2" /> Dokumentasi
                    </Link>
                    <Link to={"/kontak"}>
                      <i className="fas fa-angle-right me-2" /> Kontak Kami
                    </Link>
                  </div>
                </div>
                <div className="col-md-6 col-lg-6 col-xl-4">
                  <div className="footer-item">
                    <h4 className="mb-4 text-white">Kegiatan</h4>
                    <div className="row g-3">
                      {isLoading ? (
                        <div>Loading...</div>
                      ) : (
                        media.map((media) => (
                          <div className="col-4" key={media.id_kegiatan}>
                            <div className="footer-instagram rounded">
                              <img
                                src={`${process.env.REACT_APP_API_URL}/public/storage/${media.gambar}`}
                                className="img-fluid w-100"
                                alt=""
                              />
                              <div className="footer-search-icon">
                                <a
                                  href={`${process.env.REACT_APP_API_URL}/public/storage/${media.gambar}`}
                                  data-lightbox="footerInstagram-1"
                                  className="my-auto"
                                >
                                  <i className="fas fa-link text-white" />
                                </a>
                              </div>
                            </div>
                          </div>
                        ))
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3">
              <div className="footer-item">
                <h4 className="text-white mb-4">Lokasi</h4>
                <p className="text-white">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3949.8485295044748!2d113.21625897529496!3d-8.116900781226503!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dd65d690a862791%3A0xd74a8f892e1b8d4e!2sInstitut%20Teknologi%20dan%20Bisnis%20Widya%20Gama!5e0!3m2!1sid!2sid!4v1722602931194!5m2!1sid!2sid"
                    width={300}
                    height={200}
                    style={{ border: 0 }}
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid copyright py-4">
        <div className="container">
          <div className="row g-4 align-items-center">
            <div className="col-md-6 text-center text-md-end mb-md-0">
              <span className="text-body">
                <a href="#" className="border-bottom text-white">
                  <i className="fas fa-copyright text-light me-2" />
                  HMJ INFORMATIKA
                </a>
                , All right reserved.
              </span>
            </div>
            <div className="col-md-6 text-center text-md-start text-body">
              Designed By{" "}
              <a
                className="border-bottom text-white"
                href="https://htmlcodex.com"
              >
                Organisasi HMJ Informatika
              </a>{" "}
            </div>
          </div>
        </div>
      </div>
      <a
        href={"#"}
        className="btn btn-primary btn-lg-square rounded-circle back-to-top"
      >
        <i className="fa fa-arrow-up" />
      </a>
    </>
  );
};

export default FooterC;
