// src/index.js atau src/App.js
import React, { useEffect, useState } from "react";
import LayoutFrontend from "../component/frontend/MainLayout";
import { fetchMedia, Kegiatan } from "../backend/media/Controller";

const KegiatanPage = () => {
  const [kegiatan, setKegiatan] = useState<Kegiatan[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const loadData = async () => {
    setIsLoading(true);
    try {
      const data = await fetchMedia();
      setKegiatan(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    loadData();
  });
  console.log(kegiatan);
  return (
    <>
      <LayoutFrontend>
        <div className="container-fluid bg-light p-3">
          <div
            className="text-center mx-auto pb-5 wow fadeInUp"
            data-wow-delay="0.2s"
            style={{ maxWidth: 900 }}
          >
            <h1 className="display-4 mb-4">
              Dokumentasi Kegiatan HMJ Informatika
            </h1>
            <p className="mb-0">
              Dokumentasi Kegiatan HMJ Informatika anda bisa melihat dan
              mendoanload dokumentasi dibawah ini
            </p>
          </div>
          <div className="row g-3">
            {kegiatan.map((kegiatan) => (
              <div className="col-4 col-lg-3" key={kegiatan.id_kegiatan}>
                <a
                  href={`${process.env.REACT_APP_API_IMAGE}/storage/${kegiatan.gambar}`}
                  data-lightbox="image-1"
                  data-title={kegiatan.judul}
                >
                  <img
                    src={`${process.env.REACT_APP_API_IMAGE}/storage/${kegiatan.gambar}`}
                    className="img-fluid w-60"
                    alt=""
                  />
                </a>
              </div>
            ))}
          </div>
        </div>
      </LayoutFrontend>
    </>
  );
};

export default KegiatanPage;
