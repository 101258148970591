import { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { fetchFormData, validateFormData, submitFormData } from "./Controller";
import { useStateContext } from "../../middleware";
import FormC from "../../component/backend/Form";
import LayoutBackend from "../../component/backend/Layout";
import InputC from "../../component/backend/Input";

const AksiArchive = () => {
  const { id } = useParams();
  const isEdit = Boolean(id);
  const navigate = useNavigate();
  const { token } = useStateContext();
  const [formData, setFormData] = useState({
    nama: "",
    status: "",
    user_id: sessionStorage.getItem("user"),
    file: new File([], ""),
  });
  const [errors, setErrors] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  useEffect(() => {
    document.title = isEdit ? "Edit File" : "Tambah File";
    if (id) {
      fetchFormData(id)
        .then((data) => setFormData(data))
        .catch((error) => console.error("Error:", error));
    }
  }, [id, isEdit]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value, files } = e.target as HTMLInputElement;
    if (files && files.length > 0) {
      const file = files[0];

      // Membuat URL sementara untuk prapratampilan gambar
      const imageUrl = URL.createObjectURL(file);
      setImagePreview(imageUrl);
      setFormData((prevData) => ({
        ...prevData,
        [name]: files[0],
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    const validationErrors = validateFormData(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setIsLoading(false);
      return;
    }
    console.log(formData);
    try {
      await submitFormData(formData, isEdit);
      navigate("/file_archive", {
        state: {
          message: isEdit
            ? "Data berhasil diperbarui!"
            : "Data berhasil ditambahkan!",
        },
      });
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };
  if (!token) {
    return <Navigate to="/login" />;
  }
  return (
    <LayoutBackend>
      <FormC
        aksi={handleSubmit}
        formTitle={isEdit ? `Edit File` : `Tambah File`}
        buttonTitle={isEdit ? `Update` : `Simpan`}
        buttonType={`submit`}
        hrefTitle={`Kembali`}
        href={`/file_archive`}
        loading={isLoading}
        encType={`multipart/form-data`}
      >
        <div className="col-12">
          <InputC
            label={`Judul File`}
            input_type={`text`}
            input_id={`nama`}
            name={"nama"}
            placeholder={`Masukkan nama file`}
            value={formData.nama}
            onChange={handleChange}
          />
          <span className="text-danger">{errors.nama}</span>
        </div>
        <div className="col-12">
          <InputC
            label={`Upload File`}
            input_type={`file`}
            input_id={`file`}
            name={"file"}
            onChange={handleChange}
          />
          <span className="text-danger">NB: File PDF</span>
          {isEdit ? (
            <img src={`/pdf.png`} className="img-fluid" width={100} alt="" />
          ) : (
            imagePreview && (
              <>
                <img
                  src={`/pdf.png`}
                  className="img-fluid"
                  width={50}
                  alt="Preview"
                />
                {/* <span className="file-name">{formData.fileName}</span> */}
              </>
            )
          )}
        </div>
        <div className="col-12">
          <div className="form-group">
            <label htmlFor="status">Status</label>
            <select
              name="status"
              id="status"
              className="form-control"
              value={formData.status}
              onChange={handleChange}
            >
              <option value="">-pilih-</option>
              <option value="1">Publish</option>
              <option value="2">Private</option>
            </select>
            <span className="text-danger">{errors.status}</span>
          </div>
        </div>
      </FormC>
    </LayoutBackend>
  );
};

export default AksiArchive;
