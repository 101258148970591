import React, { useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { useStateContext } from "../../middleware";
import LayoutBackend from "../../component/backend/Layout";
import { Archive, fetchArchive } from "./Controller";
import { fetchKeluar, fetchMasuk, SuratMasuk } from "./surat/Controller";

const FilePage: React.FC = () => {
  const [archive, setArchive] = useState<Archive[]>([]);
  const [suratM, setSuratM] = useState<SuratMasuk[]>([]);
  const [suratK, setSuratK] = useState<SuratMasuk[]>([]);
  const { token } = useStateContext();

  const loadData = async () => {
    try {
      const [archiveResult, masukResult, keluarResult] = await Promise.all([
        fetchArchive(),
        fetchMasuk(),
        fetchKeluar(),
      ]);
      setArchive(archiveResult);
      setSuratM(masukResult);
      setSuratK(keluarResult);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    document.title = "Data Archive";
    loadData();
  });
  if (!token) {
    return <Navigate to="/login" />;
  }
  return (
    <LayoutBackend>
      <div className="row">
        <div className="col-md-3 col-sm-6 col-12">
          <div className="info-box shadow">
            <Link to="/file_archive" className="info-box-icon bg-warning">
              <span className="info-box-icon bg-warning">
                <i className="far fa-folder" />
              </span>
            </Link>
            <div className="info-box-content">
              <span className="info-box-text">File</span>
              <span className="info-box-number">{archive.length}</span>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-6 col-12">
          <div className="info-box shadow">
            <Link to="/surat_masuk" className="info-box-icon bg-success">
              <span className="info-box-icon bg-success">
                <i className="far fa-envelope" />
              </span>
            </Link>
            <div className="info-box-content">
              <span className="info-box-text">Surat Masuk</span>
              <span className="info-box-number">{suratM.length}</span>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-6 col-12">
          <div className="info-box shadow">
            <Link to="/surat_keluar" className="info-box-icon bg-success">
              <span className="info-box-icon bg-success">
                <i className="far fa-envelope" />
              </span>
            </Link>
            <div className="info-box-content">
              <span className="info-box-text">Surat Keluar</span>
              <span className="info-box-number">{suratK.length}</span>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-6 col-12">
          <div className="info-box shadow">
            <span className="info-box-icon bg-primary">
              <i className="far fa-file" />
            </span>
            <div className="info-box-content">
              <span className="info-box-text">Laporan</span>
              <span className="info-box-number">0 </span>
            </div>
          </div>
        </div>
      </div>
    </LayoutBackend>
  );
};

export default FilePage;
