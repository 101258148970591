/* eslint-disable jsx-a11y/iframe-has-title */
// src/App.tsx
import React, { useEffect, useRef } from "react";
import jsPDF from "jspdf";

const App: React.FC = () => {
  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    const createPdf = () => {
      const doc = new jsPDF();

      doc.text("Hello, world!", 10, 10);

      const pdfBlob = doc.output("blob");
      const pdfUrl = URL.createObjectURL(pdfBlob);

      if (iframeRef.current) {
        iframeRef.current.src = pdfUrl;
      }
    };

    createPdf();
  }, []);

  return (
    <div style={{ height: "100vh" }}>
      <iframe
        ref={iframeRef}
        style={{ width: "100%", height: "100%", border: "none" }}
      />
    </div>
  );
};

export default App;
