import ButtonC from "./Button";
import { Link } from "react-router-dom";

const FormC = (props: any) => {
  const {
    children,
    aksi,
    formTitle,
    buttonType,
    buttonTitle,
    href,
    hrefTitle,
    loading,
    encType,
  } = props;
  return (
    <>
      <div className="card card-primary">
        <div className="card-header">
          <h3 className="card-title">{formTitle}</h3>
        </div>
        <form onSubmit={aksi} encType={encType}>
          <div className="card-body">
            <div className="row"> {children}</div>
          </div>
          <div className="card-footer">
            <ButtonC type={buttonType}>
              {" "}
              {loading ? "Loading..." : buttonTitle}
            </ButtonC>
            <Link to={href} className="btn btn-danger ml-2">
              {" "}
              {hrefTitle}
            </Link>
          </div>
        </form>
      </div>
    </>
  );
};
export default FormC;
