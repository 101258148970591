import { Link } from "react-router-dom";

const LinkC = (props: any) => {
  const { children, href, color, onClick, target } = props;
  return (
    <>
      <Link
        target={target}
        to={href}
        onClick={onClick}
        className={`btn btn-sm btn-${color} mb-2 ml-2`}
      >
        {children}
      </Link>
    </>
  );
};
export default LinkC;
