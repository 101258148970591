// AnggotaController.ts
export interface Kategori {
  id: number;
  nama: string;
}
export interface Anggota {
  id_ang: number;
  nim: string;
  nama: string;
  angkatan: string;
  created_at: string;
}
export const fetchAnggota = async (): Promise<Anggota[]> => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/anggota`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  });
  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }
  const data: Anggota[] = await response.json();
  return data;
};
// Fungsi untuk mengambil data dari API
export const fetchKategori = async (): Promise<Kategori[]> => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/kategori`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  });
  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }
  const data: Kategori[] = await response.json();
  return data;
};
export const fetchKategoriHome = async (): Promise<Kategori[]> => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/kategori_home/${process.env.REACT_APP_TOKEN}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );
  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }
  const data: Kategori[] = await response.json();
  return data;
};

// Fungsi untuk memfilter data berdasarkan pencarian
export const filterData = (data: Kategori[], search: string): Kategori[] => {
  return data.filter((pengurus) =>
    pengurus.nama.toLowerCase().includes(search.toLowerCase())
  );
};

// Fungsi untuk menghitung total halaman
export const getTotalPages = (
  filteredData: Kategori[],
  showCount: number
): number => {
  return Math.ceil(filteredData.length / showCount);
};

// Fungsi untuk mendapatkan data berdasarkan halaman saat ini
export const getPaginatedData = (
  filteredData: Kategori[],
  currentPage: number,
  showCount: number
): Kategori[] => {
  return filteredData.slice(
    (currentPage - 1) * showCount,
    currentPage * showCount
  );
};

// controller.ts

export const fetchFormData = async (id: string) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/kategori/${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    );
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching form data:", error);
    throw error;
  }
};

export const validateFormData = (formData: any) => {
  const errors: any = {};

  if (!formData.nama) errors.nama = "Kategori harus diisi!";

  return errors;
};

export const submitFormData = async (formData: any, isEdit: boolean) => {
  const url = isEdit
    ? `${process.env.REACT_APP_API_URL}/update_kategori`
    : `${process.env.REACT_APP_API_URL}/kategori`;

  try {
    //cek apakah tipe formData adalah FormData, jika bukan, ubah menjadi FormData
    const body = formData instanceof FormData ? formData : new FormData();
    if (!(formData instanceof FormData)) {
      for (const key in formData) {
        body.append(key, formData[key]);
      }
    }

    const response = await fetch(url, {
      method: "POST",
      headers: {
        // "Content-Type" should not be set for FormData
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: body,
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
  } catch (error) {
    console.error("Error submitting form data:", error);
    throw error;
  }
};
