import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface Statistik {
  judul: string;
  year: number;
  month: number;
  total: number;
}

const BarChart: React.FC = () => {
  const [data, setData] = useState<Statistik[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/statistik`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const result: Statistik[] = await response.json();
        setData(result);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // Format data untuk chart
  const months = [
    "Januari",
    "Februari",
    "Maret",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Agustus",
    "September",
    "Oktober",
    "November",
    "Desember",
  ];

  // Create a unique list of months and articles
  const aggregatedData: { [key: string]: { [judul: string]: number } } = {};
  data.forEach((item) => {
    const key = `${months[item.month - 1]} ${item.year}`;
    if (!aggregatedData[key]) {
      aggregatedData[key] = {};
    }
    aggregatedData[key][item.judul] = item.total;
  });

  const labels = Object.keys(aggregatedData);
  const judulList = Array.from(new Set(data.map((item) => item.judul))); // Unique judul

  const colorPalette = [
    "#4E79A7", // Blue
    "#F28E2B", // Orange
    "#E15759", // Red
    "#76B7B2", // Teal
    "#59A14F", // Green
    "#EDC948", // Yellow
    "#B07AA1", // Purple
  ];

  const datasets = judulList.map((judul, index) => ({
    label: judul,
    data: labels.map((label) => aggregatedData[label][judul] || 0),
    backgroundColor: colorPalette[index % colorPalette.length] + "80", // Lightened color
    borderColor: colorPalette[index % colorPalette.length], // Darker color
    borderWidth: 1,
  }));

  const chartData = {
    labels,
    datasets,
  };

  const options: ChartOptions<"bar"> = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Statistik Artikel per Bulan",
      },
    },
    scales: {
      x: {
        stacked: true,
        title: {
          display: true,
          text: "Bulan",
        },
      },
      y: {
        stacked: true,
        title: {
          display: true,
          text: "Jumlah",
        },
        ticks: {
          callback: function (value) {
            // Hanya tampilkan label yang tidak nol
            if (value !== 0) {
              return value;
            }
            return "";
          },
        },
      },
    },
  };

  return <Bar data={chartData} options={options} />;
};

export default BarChart;
