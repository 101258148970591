import { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { fetchFormData, validateFormData, submitFormData } from "./Controller";
import LayoutBackend from "../../component/backend/Layout";
import FormC from "../../component/backend/Form";
import { useStateContext } from "../../middleware";
import InputC from "../../component/backend/Input";

const AksiUser = () => {
  const { id } = useParams();
  const isEdit = Boolean(id);
  const navigate = useNavigate();
  const { token } = useStateContext();
  const [formData, setFormData] = useState({
    nama: "",
    username: "",
    level: "",
    status: "",
    password: "",
  });
  const [errors, setErrors] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    document.title = isEdit ? "Edit User" : "Tambah User";
    if (id) {
      fetchFormData(id)
        .then((data) => setFormData(data))
        .catch((error) => console.error("Error:", error));
    }
  }, [id, isEdit]);

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);

    // Validate form data
    const validationErrors = validateFormData(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setIsLoading(false);
      return;
    }

    try {
      await submitFormData(formData, isEdit);
      navigate("/user", {
        state: {
          message: isEdit
            ? "Data berhasil diperbarui!"
            : "Data berhasil ditambahkan!",
        },
      });
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  if (!token) {
    return <Navigate to="/login" />;
  }

  return (
    <LayoutBackend>
      <FormC
        aksi={handleSubmit}
        formTitle={isEdit ? `Edit User` : `Tambah User`}
        buttonTitle={isEdit ? `Update` : `Simpan`}
        buttonType="submit" // Ensure this is set to "submit"
        hrefTitle={`Kembali`}
        href={`/user`}
        loading={isLoading}
      >
        <div className="col-12">
          <InputC
            label="Nama"
            name="nama"
            input_id="nama"
            input_type="text"
            value={formData.nama}
            onChange={handleChange}
            placeholder="Masukkan nama"
          />
          <span className="text-danger">{errors.nama}</span>
        </div>
        <div className="col-12">
          <InputC
            label="Username"
            name="username"
            input_id="username"
            input_type="text"
            value={formData.username}
            onChange={handleChange}
            placeholder="Masukkan username"
          />
          <span className="text-danger">{errors.username}</span>
        </div>
        <div className="col-12">
          <InputC
            label="Password"
            name="password"
            input_id="password"
            input_type="password"
            // value={formData.username}
            onChange={handleChange}
            placeholder="**********"
          />
          <span className="text-danger">{errors.password}</span>
        </div>
        <div className="col-12">
          <label htmlFor="level">Level</label>
          <select
            name="level"
            id="level"
            className="form-control"
            value={formData.level}
            onChange={handleChange}
          >
            <option value="">-pilih-</option>
            <option value="1">Admin</option>
            <option value="2">User</option>
          </select>
          <span className="text-danger">{errors.level}</span>
        </div>
        <div className="col-12">
          <label htmlFor="status">Status</label>
          <select
            name="status"
            id="status"
            className="form-control"
            value={formData.status}
            onChange={handleChange}
          >
            <option value="">-pilih-</option>
            <option value="1">Aktif</option>
            <option value="2">Tidak</option>
          </select>
          <span className="text-danger">{errors.status}</span>
        </div>
      </FormC>
    </LayoutBackend>
  );
};

export default AksiUser;
