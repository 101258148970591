import React, { useEffect, useState } from "react";
import {
  fetchMedia,
  filterData,
  getTotalPages,
  getPaginatedData,
  Kegiatan,
} from "./Controller";
import { Navigate, useLocation } from "react-router-dom";
import { useStateContext } from "../../middleware";
import LayoutBackend from "../../component/backend/Layout";
import TableC from "../../component/backend/Table";
import LinkC from "../../component/backend/Link";

const MediaPage: React.FC = () => {
  const [data, setData] = useState<Kegiatan[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [showCount, setShowCount] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const location = useLocation();
  const [message, setMessage] = useState(location.state?.message);
  const { token } = useStateContext();
  // Mengambil data dari API
  useEffect(() => {
    document.title = "Data Kegiatan";
    const loadData = async () => {
      setLoading(true);
      try {
        const data = await fetchMedia();
        setData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, []);
  console.log(data);
  // Mengatur nilai input pencarian dan mereset halaman ke pertama saat nilai pencarian berubah
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setCurrentPage(1);
  };

  // Mengatur jumlah data yang ditampilkan per halaman dan mereset halaman ke pertama saat jumlah data yang ditampilkan berubah
  const handleShowCountChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setShowCount(Number(e.target.value));
    setCurrentPage(1);
  };

  // Mengatur halaman berikutnya
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Mengatur halaman sebelumnya
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Memfilter data berdasarkan nilai input pencarian
  const filteredData = filterData(data, search);

  // Menghitung total halaman
  const totalPages = getTotalPages(filteredData, showCount);

  // Mendapatkan data yang akan ditampilkan berdasarkan halaman saat ini
  const paginatedData = getPaginatedData(filteredData, currentPage, showCount);
  const handleDelete = async (id: number) => {
    if (window.confirm("Apakah Anda yakin ingin menghapus data ini?")) {
      await deleteData(id);
    }
  };
  console.log(paginatedData);
  const deleteData = async (id: number) => {
    try {
      await fetch(`${process.env.REACT_APP_API_URL}/kegiatan/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });
      setData(data.filter((kegiatan) => kegiatan.id_kegiatan !== id));
      setMessage("Data Berhasil dihapus");
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };

  if (!token) {
    return <Navigate to="/login" />;
  }
  return (
    <LayoutBackend>
      {message && (
        <div className="alert alert-success">
          {" "}
          <i className="fas fa-check"></i> {message}
        </div>
      )}
      <TableC title="Data Kegiatan" href={`/tambah_media`} titlehref={`Tambah`}>
        <div className="table-responsive">
          <div className="col-sm-12">
            <div className="d-flex justify-content-between align-items-center">
              <div className="dataTables_length">
                <label>
                  Show{" "}
                  <select
                    name="example_length"
                    aria-controls="example"
                    className="form-select form-select-sm"
                    value={showCount}
                    onChange={handleShowCountChange}
                  >
                    <option value={10}>10</option>
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                  </select>
                </label>
              </div>
              <div className="dataTables_filter">
                <label className="w-100">
                  Search:
                  <input
                    type="search"
                    className="form-control form-control-sm"
                    placeholder=""
                    aria-controls="example"
                    value={search}
                    onChange={handleSearchChange}
                  />
                </label>
              </div>
            </div>
          </div>
          {loading ? (
            <center>Loading....</center>
          ) : (
            <>
              <table
                id="example"
                className="table table-striped"
                style={{ width: "100%" }}
              >
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Judul</th>
                    <th>Kegiatan</th>
                    <th>Aksi</th>
                  </tr>
                </thead>
                <tbody>
                  {paginatedData.length > 0 ? (
                    paginatedData.map((kegiatan, index) => (
                      <tr key={kegiatan.id_kegiatan}>
                        <td>{(currentPage - 1) * showCount + index + 1}</td>
                        <td>{kegiatan.judul}</td>
                        <td>
                          <img
                            src={`${process.env.REACT_APP_API_IMAGE}/storage/${kegiatan.gambar}`}
                            alt=""
                            width={100}
                          />
                        </td>
                        <td>
                          <LinkC
                            color={`warning`}
                            href={`/media/${kegiatan.id_kegiatan}`}
                          >
                            <i className="fa fa-edit"></i>
                          </LinkC>
                          <LinkC
                            color={`danger`}
                            onClick={() => handleDelete(kegiatan.id_kegiatan)}
                          >
                            <i className="fa fa-trash"></i>
                          </LinkC>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={4} className="text-center">
                        Tidak ada data
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div
                id="example_paginate"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <ul className="pagination">
                  <li
                    className={`paginate_button page-item previous ${
                      currentPage === 1 ? "disabled" : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={handlePrevPage}
                      disabled={currentPage === 1}
                    >
                      Prev
                    </button>
                  </li>
                  {[...Array(totalPages)].map((_, pageIndex) => (
                    <li
                      key={pageIndex}
                      className={`paginate_button page-item ${
                        currentPage === pageIndex + 1 ? "active" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={() => setCurrentPage(pageIndex + 1)}
                      >
                        {pageIndex + 1}
                      </button>
                    </li>
                  ))}
                  <li
                    className={`paginate_button page-item next ${
                      currentPage === totalPages ? "disabled" : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={handleNextPage}
                      disabled={currentPage === totalPages}
                    >
                      Next
                    </button>
                  </li>
                </ul>
              </div>
            </>
          )}
        </div>
      </TableC>
    </LayoutBackend>
  );
};

export default MediaPage;
