import { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import FormC from "../../../component/backend/Form";
import InputC from "../../../component/backend/Input";
import LayoutBackend from "../../../component/backend/Layout";
import {
  fetchFormData,
  validateFormData,
  submitFormData,
  fetchAnggota,
  Anggota,
} from "./Controller";
import { useStateContext } from "../../../middleware";

const AksiKategori = () => {
  const { id } = useParams();
  const isEdit = Boolean(id);
  const navigate = useNavigate();
  const { token } = useStateContext();
  const [anggota, setAnggota] = useState<Anggota[]>([]);
  const [formData, setFormData] = useState({
    nama: "",
  });
  const [errors, setErrors] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    document.title = isEdit ? "Edit Anggota" : "Tambah Anggota";
    if (id) {
      fetchFormData(id)
        .then((data) => setFormData(data))
        .catch((error) => console.error("Error:", error));
    }
  }, [id, isEdit]);

  useEffect(() => {
    const loadData = async () => {
      try {
        const data = await fetchAnggota();
        setAnggota(data); // Set initial filtered data
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    loadData();
  }, []);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target as HTMLInputElement;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    const validationErrors = validateFormData(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setIsLoading(false);
      return;
    }

    try {
      await submitFormData(formData, isEdit);
      navigate("/kategori", {
        state: {
          message: isEdit
            ? "Data berhasil diperbarui!"
            : "Data berhasil ditambahkan!",
        },
      });
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  if (!token) {
    return <Navigate to="/login" />;
  }

  return (
    <LayoutBackend>
      <FormC
        aksi={handleSubmit}
        formTitle={isEdit ? `Edit Pengurus` : `Tambah Pengurus`}
        buttonTitle={isEdit ? `Update` : `Simpan`}
        buttonType={`submit`}
        hrefTitle={`Kembali`}
        href={`/pengurus`}
        loading={isLoading}
        encType={`multipart/form-data`}
      >
        <div className="col-12">
          <InputC
            label={`Kategori`}
            input_type={`text`}
            input_id={`nama`}
            name={"nama"}
            value={formData.nama}
            onChange={handleChange}
            placeholder={`Kategori Artikel`}
          />
          <span className="text-danger">{errors.nama}</span>
        </div>
      </FormC>
    </LayoutBackend>
  );
};

export default AksiKategori;
