/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import LayoutBackend from "../../../component/backend/Layout";
import { Anggota, fetchAnggotaResult, fetchFormData } from "./Controller";
import { Navigate, useLocation, useParams } from "react-router-dom";
import { useStateContext } from "../../../middleware";
import LinkC from "../../../component/backend/Link";

const DetailAnggota: React.FC = () => {
  const [data, setData] = useState<Anggota>();
  const [loading, setLoading] = useState(true);

  const { token } = useStateContext();
  // Mengambil data dari API
  useEffect(() => {
    document.title = "Detail Data Anggota";
    const loadData = async () => {
      try {
        const data = await fetchFormData(
          window.location.pathname.split("/")[2]
        );
        setData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, []);

  // Mendapatkan data yang akan ditampilkan berdasarkan halaman saat ini

  if (!token) {
    return <Navigate to="/login" />;
  }
  return (
    <LayoutBackend>
      <div className="row">
        <div className="col-md-3">
          {/* Profile Image */}
          <div className="card card-primary card-outline">
            <div className="card-body box-profile">
              <div className="text-center">
                <img
                  className="profile-user-img img-fluid img-circle size-100"
                  src={
                    data?.gambar === null
                      ? "/default.png"
                      : `${process.env.REACT_APP_API_IMAGE}/storage/${data?.gambar}`
                  }
                  alt="User profile picture"
                />
              </div>
              <h3 className="profile-username text-center">{data?.nama}</h3>
              <p className="text-muted text-center">{data?.divisi}</p>
              <ul className="list-group list-group-unbordered mb-3">
                <li className="list-group-item">
                  <b>NIM</b> <a className="float-right">{data?.nim}</a>
                </li>
                <li className="list-group-item">
                  <b>Angkatan</b>{" "}
                  <a className="float-right">
                    {new Date(data?.created_at || Date.now()).getFullYear()}
                  </a>
                </li>
                <li className="list-group-item">
                  <b>Status</b>{" "}
                  <a className="float-right">
                    {data?.status === "1" ? "Aktif" : "Tidak Aktif"}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* /.col */}
        <div className="col-md-9">
          <div className="card">
            <div className="card-header p-2">
              <ul className="nav nav-pills">
                <li className="nav-item">
                  <h3>Detail Anggota</h3>
                </li>
              </ul>
            </div>
            {/* /.card-header */}
            <div className="card-body">
              <div className="tab-content">
                <div className="tab-pan active" id="settings">
                  <form className="form-horizontal">
                    <div className="form-group row">
                      <label
                        htmlFor="inputName"
                        className="col-sm-2 col-form-label"
                      >
                        Tempat Lahir
                      </label>
                      <div className="col-sm-10">
                        <input
                          className="form-control"
                          value={data?.tempat}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="inputName"
                        className="col-sm-2 col-form-label"
                      >
                        Tanggal Lahir
                      </label>
                      <div className="col-sm-10">
                        <input
                          className="form-control"
                          value={data?.tgl}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="inputName"
                        className="col-sm-2 col-form-label"
                      >
                        Gender
                      </label>
                      <div className="col-sm-10">
                        <input
                          className="form-control"
                          value={data?.jk === 1 ? "Laki-laki" : "Perempuan"}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="inputName"
                        className="col-sm-2 col-form-label"
                      >
                        No Hp
                      </label>
                      <div className="col-sm-10">
                        <input
                          className="form-control"
                          value={data?.no_hp}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="inputName"
                        className="col-sm-2 col-form-label"
                      >
                        Motto
                      </label>
                      <div className="col-sm-10">
                        <textarea
                          className="form-control"
                          value={data?.motto}
                          disabled
                        />
                      </div>
                    </div>
                  </form>
                  <LinkC href="/anggota" color="danger">
                    Kembali
                  </LinkC>
                </div>
                {/* /.tab-pane */}
              </div>
              {/* /.tab-content */}
            </div>
            {/* /.card-body */}
          </div>
          {/* /.card */}
        </div>
        {/* /.col */}
      </div>
    </LayoutBackend>
  );
};

export default DetailAnggota;
